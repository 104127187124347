import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const Form = ({
  handleSubmit,
  handleChange,
  submitButtonText,
  formData,
  twoInRow,
}) => {
  const animatedComponents = makeAnimated();

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex flex-row flex-wrap">
        {Object.entries(formData).map(([name, field]) => (
          <React.Fragment key={name}>
            {field.show && (
              <div
                className={`sign__input-wrapper mb-25 ${
                  twoInRow && field.type !== "textarea"
                    ? "col-xxl-6 col-xl-6 col-lg-6 col-md-6 px-2 col-sm-12 col-12"
                    : "col-12"
                }`}
              >
                <h5>{field.label}</h5>
                <div className="sign__input">
                  {field.type === "select" && (
                    <select
                      name={name}
                      value={field.value}
                      onChange={handleChange}
                      required
                    >
                      {field.options.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          data-dialcode={option.dialCode}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  )}
                  {field.type === "multi-select" && (
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={field.value}
                      isMulti
                      options={field.options}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      required
                    />
                  )}
                  {field.type === "checkbox" && (
                    <input
                      type="checkbox"
                      name={name}
                      placeholder={field.label}
                      checked={field.value}
                      onChange={handleChange}
                      required
                    />
                  )}
                  {field.type === "tel" && (
                    <div className="d-flex flex-row ">
                      <div className=" col-4 col-lg-2 ">
                        <input
                          type="text"
                          name="phone_dialcode"
                          value={field.dialCode}
                          className="form-control  text-start pe-0 text-80 rounded-0 rounded-start"
                          style={{
                            fontSize: "14px",
                            backgroundColor: "#f5f5f5",
                          }}
                          disabled
                        />
                      </div>
                      <div className="col-8 col-lg-10 p-0">
                        <input
                          type={field.type}
                          name={name}
                          value={field.value}
                          onChange={handleChange}
                          placeholder={field.label}
                          pattern={field.regex ? field.regex : null}
                          className="form-control  text-start pe-0 rounded-0 rounded-end"
                          title={field.error}
                          autoComplete={field.autoCompleteOff ? "off" : "on"}
                          required
                        />
                      </div>
                    </div>
                  )}
                  {field.type === "textarea" && (
                    <div className="col-12">
                      <div className="contact__input textarea">
                        <textarea
                          name={name}
                          value={field.value}
                          onChange={handleChange}
                          placeholder={field.placeholder}
                          required
                        />
                        <i className={field.icon}></i>
                      </div>
                    </div>
                  )}
                  {field.type !== "select" &&
                    field.type !== "multi-select" &&
                    field.type !== "checkbox" &&
                    field.type !== "textarea" &&
                    field.type !== "tel" && (
                      <div className="col-12">
                        <input
                          type={field.type}
                          name={name}
                          value={field.value}
                          onChange={handleChange}
                          placeholder={field.label}
                          pattern={field.regex ? field.regex : null}
                          title={field.error}
                          autoComplete={field.autoCompleteOff ? "off" : "on"}
                          required
                        />
                      </div>
                    )}

                  {field.type !== "multi-select" &&
                    field.type !== "textarea" && <i className={field.icon}></i>}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      <button className="m-btn m-btn-4 w-100">{submitButtonText}</button>
    </form>
  );
};
export default Form;
