export const clientSection = {
  logos: [
    {
      src: `${process.env.PUBLIC_URL}/img/Springboard Events.jpg`,
      alt: "Springboard Events",
    },
    {
      src: `${process.env.PUBLIC_URL}/img/Future Group Proof Media.png`,
      alt: "Future Group Proof Media",
    },
    {
      src: `${process.env.PUBLIC_URL}/img/GES.png`,
      alt: "GES",
    },

    {
      src: `${process.env.PUBLIC_URL}/img/Scottish Dental Show.png`,
      alt: "Scottish Dental Show",
    },
    {
      src: `${process.env.PUBLIC_URL}/img/Visit.png`,
      alt: "Visit",
    },
    {
      src: `${process.env.PUBLIC_URL}/img/informa connect.png`,
      alt: "informa connect",
    },
  ],
  header: "Our Clients",
};
