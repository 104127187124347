import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({metaData}) {
    const {title, description, keywords} = metaData
return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<meta name='Description' content={description} />
<meta name='Keywords' content={keywords} />
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:title" content={title} />
<meta property="og:description" content={description} />
<meta name='og:keywords' content={keywords} />
{ /* End Facebook tags */ }
</Helmet>
)
}