import axios from "axios";

const fetchData = async (baseURL, token) => {
  try {
    const data = await axios.get(baseURL, {
      headers: {
        Authorization: `Bearer ${
          token || window.localStorage.getItem("token")
        }`,
      },
    });
    return { ok: true, data: data.data };
  } catch (err) {
    console.log("ERROR FETCH", err);
    return { ok: false, err };
  }
};

export default fetchData;
