const header = {
  title: "Transmit - Email",
  content: "Easily schedule and send important event updates.",

  bg_img: `${process.env.PUBLIC_URL}/img/shape/shape-1.png`,
};

const transmitEmailSections = [
  {
    title: "What is the Transmit Email module?",
    content: [
      "This module allows you to schedule and send emails to your event participants, such as event updates or e-newsletter bulletins with useful event updates.",
      "Send targeted emails when your attendees arrive at your event based on their registration type so that you are sending personalised information to help enhance their experience at your event.",
      "Easily track the success of your email campaigns with our built-in analytics, including open rates and click-throughs. Schedule multiple emails in advance to ensure all participants are informed about any event changes or updates.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Transmit-Email-2.png`,
    imgAlt: "Transmit Email 2",
    ImageOnLeft: false,
  },
  {
    title: "How does it work?",
    content: [
      "The optimum way to manage your data is that we integrate with your registration vendor, for free, as part of our service to you.",
      "You build your email templates and schedule to be triggered to send emails at the right moment when you need to communicate with your event attendees at your event.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Transmit-Email-1.png`,
    imgAlt: "Transmit Email 1",
    ImageOnLeft: true,
  },
  {
    title: "Generate email revenue",
    content: [
      "You can sell prime sponsorship on important event emails to your sponsors or exhibitors that attendees receive whilst at your event.",
      "By segmenting your audience based on registration type, you can sell relevant exhibition packages based on the interests of attendees and needs of your sponsors or exhibitors when attendees are within your exhibition hall.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Transmit-Email-2.png`,
    imgAlt: "Transmit Email 2",
    ImageOnLeft: false,
  },
];

const footer = {
  title: "What's included in the event module",
  contentFirst: [
    { text: "Integration with your registration vendor", link: "#" },
    {
      text: "Advanced reporting detailing attendee journey from all emails",
      link: "#",
    },
  ],
  contentSecond: [
    {
      text: "Real-time email sends -send important event updates & generate revenue",
      link: "#",
    },
    {
      text: "High value sponsor opportunities with direct, real-time at-event messaging",
      link: "#",
    },
  ],
};
export { transmitEmailSections, header, footer };
