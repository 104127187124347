import React, { useEffect } from "react";
import CtaDemo from "../components/ctaDemo";
import Modules from "../components/modules";
import productData from "../constants/modules";
import SEO from "../components/seo";
import { metaData } from "../constants/metaData";

import generateModifiedPaths from "../service/generateModifiedPaths";

function ProductArea(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return (
    <>
    <SEO metaData={metaData.modules} />
      <div className="bg-shape">
        <img
          srcSet={generateModifiedPaths("/img/shape/shape-1.png")}
          sizes="100vw"
          src={`${process.env.PUBLIC_URL}/img/shape/shape-1.png`}
          alt=""
        />
      </div>
      <section className="product__area grey-bg-16 pt-105 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
              <div className="page__title-wrapper text-center mb-60">
                <h2 className="page__title-2">Modules</h2>
                <p>
                  A suite of eventtech products that make it easy for event
                  organizers to deliver enhanced experiences to their event
                  participants.
                </p>
              </div>
            </div>
          </div>
          <Modules productData={productData} />
        </div>

        <div className="mt-50">
          <CtaDemo boxStyle="box" />
        </div>
      </section>
    </>
  );
}
export default ProductArea;
