import React, { useState, useContext } from "react";
import useAPI from "../service/useAPI";
import postData from "../service/postData";
import {
  FlashDispatchContext,
  errorFlash,
  successFlash,
} from "../contexts/flash";

function QuickContact() {
  const FlashDispatch = useContext(FlashDispatchContext);

  const postAPI = useAPI(postData);
  const [email, setEmail] = useState("");

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    const submitResponse = await postAPI.request(
      `${process.env.REACT_APP_NODE_URL}/api/contacts/quick`,
      {
        email: email,
      }
    );
    if (submitResponse.ok) {
      successFlash(FlashDispatch, "Thank you, we will be in touch!");
      setEmail("");
    } else {
      errorFlash(
        FlashDispatch,
        "Woops, something went wrong. Please try submitting your email again"
      );
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <section
      className="subscribe__area p-relative pt-100 pb-110"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/shape/subscribe-bg.jpg)`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xxl-12">
            <div
              className="subscribe__content text-center wow fadeInUp"
              data-wow-delay=".3s"
            >
              <h3 className="subscribe__title">Want to enhance your events?</h3>
              <p>Get in touch with the team today</p>
              <div
                className="subscribe__form wow fadeInUp"
                data-wow-delay=".5s"
              >
                {/* TODO */}
                <form onSubmit={handleFormSubmit}>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Email Address"
                    required
                  />
                  <button type="submit" className="m-btn m-btn-black">
                    <span></span> submit{" "}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default QuickContact;
