import React, { useEffect } from "react";
import WhyChoose from "../components/whyChoose";
import CtaDemo from "../components/ctaDemo";
import QuickContact from "../components/quickContact";
import SEO from "../components/seo";
import { metaData } from "../constants/metaData";
import generateModifiedPaths from "../service/generateModifiedPaths";

function About(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return (
    <>
    <SEO metaData={metaData.about}/>
      <div className="bg-shape">
        <img
          srcSet={generateModifiedPaths("/img/shape/shape-1.png")}
          sizes="100vw"
          src={`${process.env.PUBLIC_URL}/img/shape/shape-1.png`}
          alt=""
        />
      </div>
      <section className="about__area pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1">
              <div className="about__wrapper">
                <span className="about__sub-title">About Events Enhanced</span>
                <h3 className="about__title">Made by event professionals</h3>
                <div className="about__content">
                  <p className="about__text">
                    We are a team of experienced event professionals who
                    understand your needs. With over 50 years of experience
                    between the team, we have designed and delivered some of the
                    world's most high-profile events.
                  </p>
                  <p className="about__text">
                    This is why we are passionate about helping you create
                    incredible event experiences for your attendees and keeping
                    things simple.{" "}
                  </p>
                  <p className="about__sub-text">
                    When you're ready to enhance your events, we're here to
                    help.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChoose />
      <CtaDemo />
      <div className="community__area pb-140">
        <div className="container">
          <div className="row">
            <div className="col-xxl-10 offset-xxl-1">
              <div
                className="community__wrapper p-relative wow fadeInUp"
                data-wow-delay=".7s"
              >
                <img
                  className="community-map"
                  sizes="(max-width: 575px) 100vw,(max-width: 768px) 516px,(max-width: 992px) 696px,(max-width: 1200px) 936px,(max-width: 1400px) 1116px, 1076px"
                  srcSet={generateModifiedPaths("/img/shape/map.png")}
                  src={`${process.env.PUBLIC_URL}/img/shape/map.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuickContact />
    </>
  );
}

export default About;
