const header = {
  title: "Enhanced Pass",
  content:
    "Streamline your event check-in process with a badge in the attendees' phone wallet.",

  bg_img: `${process.env.PUBLIC_URL}/img/shape/shape-1.png`,
  imgSrc: `${process.env.PUBLIC_URL}/img/Enhanced-Pass-1.a.png`,
  imgSrc2: `${process.env.PUBLIC_URL}/img/Enhanced-Pass-4.png`,
  imgAlt: "Enhanced Pass devices",
  resize: true,
};

const passkitSections = [
  {
    title: "What is the event Enhanced Pass?",
    content: [
      "The enhanced pass is an event registration badge that uses geolocation technology to pop up on an event participant's phone as they arrive close to the venue.",
      "This allows them to quickly scan their pass and enter your event.",
      "The enhanced pass is a great way to streamline the check-in process and make it more efficient for both event staff and attendees.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Enhanced-Pass-2.b.png`,
    imgAlt: "Enhanced Pass iphone",
    ImageOnLeft: false,
    resize: true,
  },
  {
    title: "How does it work?",
    content: [
      "Once an event participant registers for your event, they'll be able to download their enhanced pass and add it to their Apple Wallet or Google Wallet.",
      "Using geolocation technology, when the event participant gets close to your venue, their confirmation pass will be displayed on their phone for easy scanning and badge collection. They could also use this as their event badge, reducing your printed waste.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Enhanced-Pass-2.c.png`,
    imgAlt: "Enhanced Pass android",
    ImageOnLeft: true,
  },
  {
    title: "Now available on your Apple Watch",
    content: [
      "Save your attendees even more time by delivering their Enhanced Pass straight to their Apple Watch! When they arrive at the event, they can quickly scan their pass without even having to take out their phone.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Apple-Watch-Enhanced-Pass.gif`,
    imgAlt: "Enhanced Pass apple watch",
    ImageOnLeft: false,
    // video: true,
  },
  {
    title: "Generate additional event revenue",
    content: [
      "You can generate significant revenue by showcasing your sponsor's brand and message in the palm of every single event participant. You could even add different sponsors to each pass, depending on your delegate type.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Enhanced-Pass-5.png`,
    imgAlt: "Enhanced Pass iphone 2",
    ImageOnLeft: true,
  },
];

const footer = {
  title: "What's included in the event module",
  contentFirst: [
    { text: "Integration with your registration badge supplier", link: "#" },
    {
      text: "Apple Wallet branded event pass, offline with Geolocation",
      link: "#",
    },
  ],
  contentSecond: [
    {
      text: "Google Wallet branded event pass, offline with Geolocation",
      link: "#",
    },
  ],
};
export { passkitSections, header, footer };
