import React, { useEffect } from "react";
import transmitSections from "../constants/transmit";
import CtaDemo from "../components/ctaDemo";
import Modules from "../components/modules";
import SEO from "../components/seo";
import { metaData } from "../constants/metaData";
import generateModifiedPaths from "../service/generateModifiedPaths";

function Transmit(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return (
    <>
    <SEO metaData={metaData.transmit}/>
      <div className="bg-shape">
        <img
          srcSet={generateModifiedPaths("/img/shape/shape-1.png")}
          sizes="100vw"
          src={`${process.env.PUBLIC_URL}/img/shape/shape-1.png`}
          alt=""
        />
      </div>
      <section className="support__area po-rel-z1 pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
              <div className="page__title-wrapper text-center mb-60">
                <h2 className="page__title-2">
                  Transmit Modules - Keeping Your Event Attendees Informed and
                  Engaged
                </h2>
                <p className="mt-40">
                  At Enhanced Events, we understand the importance of keeping
                  your attendees informed and engaged before, during, and after
                  your event.
                </p>
                <p>
                  That's why we're excited to introduce our suite of Transmit
                  Modules - a powerful set of communication tools that allows
                  you to do just that!
                </p>
                <p>
                  With the Transmit Module, you can send timely event updates,
                  be personalized in your approach to what messages you send,
                  segment your audience based on your registration type, help
                  them find their event pass more quickly, and much more.
                </p>
                <p>
                  Take your event to the next level with the Transmit suite of
                  modules.
                </p>
              </div>
            </div>
          </div>

          {/* <div className="row">
            {transmitSections.map((section, index) => (
              <div className="col-md-6" key={index}>
                <div
                  className="support__item mb-30 text-center white-bg transition-3 wow fadeInUp"
                  data-wow-delay={section.delay}
                >
                  <div className="support__content">
                    <h3 className="support__title">{section.title}</h3>
                    <p>{section.description}</p>
                    <div className="mt-25">
                      <Link
                        to={section.link}
                        className="m-btn m-btn-border m-btn-border-6"
                      >
                        <span></span> details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>*/}
          <Modules productData={transmitSections} />
        </div>
      </section>
      <CtaDemo boxStyle="box" />
    </>
  );
}

export default Transmit;
