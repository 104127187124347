import React, { useEffect } from "react";

function Page404(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return (
    <div className="d-flex overflow-auto m-auto align-self-stretch">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>The page you are looking for does not exist.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page404;
