import React from "react";
import { Link } from "react-router-dom";

import paths from "../constants/routers";

function Footer() {
  return (
    <footer>
      <div className="footer__area footer-bg">
        <div className="footer__top pt-90 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div
                  className="footer__widget mb-40 wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  <div className="footer__widget-head">
                    <h4 className="footer__widget-title">
                      Events Enhanced Limited
                    </h4>
                  </div>
                  <div className="footer__widget-content">
                    <p>
                      Registered in England and Wales Registration Number
                      14209064. VAT Number GB 420 6135 40
                    </p>

                    <div className="footer__lang">
                      <span>
                        <Link to="#">US</Link> English
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div
                  className="footer__widget mb-40 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="footer__widget-head mb-35">
                    <h4 className="footer__widget-title">Follow our Socials</h4>
                  </div>
                  <div className="footer__widget-content">
                    <div className="footer__social mb-30">
                      <ul>
                        <li>
                          <Link
                            to="https://www.linkedin.com/company/events-enhanced/about/"
                            className="fb"
                            target="_blank"
                          >
                            <i className="fab fa-linkedin"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-4">
                <div
                  className="footer__widget mb-40 wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  <div className="footer__widget-head">
                    <h4 className="footer__widget-title">Modules</h4>
                  </div>
                  <div className="footer__widget-content">
                    <div className="footer__link">
                      <ul>
                        <li>
                          <Link to={paths["modules::transmit"]}>Transmit</Link>
                        </li>
                        <li>
                          <Link to={paths["modules::passkit"]}>
                            Enhanced Pass
                          </Link>
                        </li>
                        <li>
                          <Link to={paths["modules::leaderboard"]}>
                            Leaderboard
                          </Link>
                        </li>
                        <li>
                          <Link to={paths["modules::showcase"]}>
                            Product Showcase
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-4">
                <div
                  className="footer__widget mb-40 wow fadeInUp"
                  data-wow-delay=".9s"
                >
                  <div className="footer__widget-head">
                    <h4 className="footer__widget-title">Info</h4>
                  </div>
                  <div className="footer__widget-content">
                    <div className="footer__link">
                      <ul>
                        <li>
                          <Link to={paths.about}>About Us</Link>
                        </li>
                        <li>
                          <Link to={paths.support}>Support</Link>
                        </li>
                        {/* <li>
                          <Link to={paths["modules::pricing"]}>
                            Modules Pricing
                          </Link>
                        </li> */}
                        <li>
                          <Link to={paths.contact}>Contact</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="container">
            <div className="footer__bottom-inner">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="footer__copyright wow fadeInUp"
                    data-wow-delay=".5s"
                  >
                    <p>
                      Copyright &copy;{" "}
                      {new Date().getFullYear()} Events Enhanced Limited
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="footer__bottom-link wow fadeInUp text-md-end"
                    data-wow-delay=".8s"
                  >
                    <ul>
                      <li>
                        <Link to={paths.privacy}>Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
