import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import paths from "../constants/routers";
import Sidebar from "./sideBar";
import ReactGA from "react-ga4";

ReactGA.initialize("G-D6E5XB1B89");

function Header() {
  const location = useLocation();
  const namespace = location.pathname.split("/")[1];
  const [sidebarOpened, setSidebarOpened] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);
  const handleSidebarToggle = () => {
    setSidebarOpened(!sidebarOpened);
  };
  // Extract the namespace from the current route
  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <>
      <header>
        <div className="header__area white-bg" id="header-sticky">
          <div className="container mw-100">
            <div className="row align-items-center">
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-6">
                <div className="logo">
                  <Link to={paths.home}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/ee_logo.png`}
                      className="img-fluid"
                      alt="Events Enhanced Logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 d-none d-lg-block">
                <div className="main-menu">
                  <nav id="mobile-menu">
                    <ul>
                      <li className={isActive(paths.home) ? "active" : ""}>
                        <Link to={paths.home}>Home</Link>
                      </li>
                      <li
                        className={`has-dropdown ${
                          namespace === "modules" ? "active" : ""
                        }`}
                      >
                        <Link to={paths["modules"]}>Modules</Link>
                        <ul className="submenu">
                          <li>
                            <Link to={paths["modules::transmit"]}>
                              Transmit
                            </Link>
                            <ul className="submenu">
                              <li>
                                <Link to={paths["modules::transmit"]}>
                                  Transmit Overview
                                </Link>
                              </li>
                              <li className="d-none d-md-block">
                                <hr />
                              </li>
                              <li>
                                <Link to={paths["modules::transmit::email"]}>
                                  Email
                                </Link>
                              </li>
                              <li>
                                <Link to={paths["modules::transmit::whatsapp"]}>
                                  WhatsApp
                                </Link>
                              </li>
                              {/* <li>
                                <Link to={paths["modules::transmit::telegram"]}>
                                  Telegram
                                </Link>
                              </li> */}
                              <li>
                                <Link to={paths["modules::transmit::sms"]}>
                                  SMS
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to={paths["modules::passkit"]}>
                              Enhanced Pass
                            </Link>
                          </li>
                          <li>
                            <Link to={paths["modules::leaderboard"]}>
                              Leaderboard
                            </Link>
                          </li>
                          <li>
                            <Link to={paths["modules::showcase"]}>
                              Product Showcase
                            </Link>
                          </li>
                          <li className="d-none d-md-block">
                            <hr />
                          </li>
                          {/* <li>
                            <Link to={paths["modules::pricing"]}>Pricing</Link>
                          </li> */}
                          <li>
                            <Link to={paths["modules::demo"]}>Live Demo</Link>
                          </li>
                        </ul>
                      </li>
                      <li
                        className={
                          isActive(paths["modules::demo"]) ? "active" : ""
                        }
                      >
                        <Link to={paths["modules::demo"]}>Live Demo</Link>
                      </li>
                      <li className={isActive(paths.support) ? "active" : ""}>
                        <Link to={paths.support}>Support</Link>
                      </li>
                      <li className={isActive(paths.about) ? "active" : ""}>
                        <Link to={paths.about}>About</Link>
                      </li>
                      <li className={isActive(paths.blogs) ? "active" : ""}>
                        <Link to={paths.blogs}>Blogs</Link>
                      </li>
                      <li className={isActive(paths.contact) ? "active" : ""}>
                        <Link to={paths.contact}>Contact</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-8 col-6">
                <div className="header__action footer__widget-content mr-90">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="d-none d-lg-block footer__social mr-20 ml-auto ">
                      <ul>
                        <li>
                          <Link
                            to="https://www.linkedin.com/company/events-enhanced/about/"
                            className="fb"
                            target="_blank"
                          >
                            <i className="fab fa-linkedin"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="d-none d-lg-block">
                      <Link to={paths.login} target="_blank">
                        <i className="fa fa-unlock"></i> Log In
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="sidebar__menu d-lg-none">
                  <div
                    className="sidebar-toggle-btn"
                    onClick={handleSidebarToggle}
                    id="sidebar-toggle"
                  >
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Sidebar
        sidebarOpened={sidebarOpened}
        handleSidebarToggle={handleSidebarToggle}
      />
    </>
  );
}

export default Header;
