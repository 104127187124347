import React, { useEffect } from "react";
import {
  transmitSmsSections,
  header,
  footer,
} from "../constants/transmitSmsSections";
import Sections from "../components/sections";

function TransmitSms(props) {
  useEffect(() => {
    document.title = props.title
  }, [props.title]);
  return (
    <Sections header={header} footer={footer} body={transmitSmsSections} />
  );
}

export default TransmitSms;
