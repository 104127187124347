import React, { useEffect } from "react";
import Modules from "../components/modules";
import CtaDemo from "../components/ctaDemo";
import WhyChoose from "../components/whyChoose";
import QuickContact from "../components/quickContact";
import productData from "../constants/modules";
import LogoList from "../components/logoList";
import { clientSection } from "../constants/logos";
import SEO from "../components/seo";
import { metaData } from "../constants/metaData";

function Home(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return (
    <>
    <SEO metaData={metaData.home}/>
      <section className="hero__area hero__height grey-bg-3 d-flex align-items-center">
        <div className="hero__shape">
          <img
            className="circle"
            src={`${process.env.PUBLIC_URL}/img/square-blue.svg`}
            alt="circle"
          />
          <img
            className="circle-2"
            src={`${process.env.PUBLIC_URL}/img/square-orange.svg`}
            alt="circle"
          />
          <img
            className="square"
            src={`${process.env.PUBLIC_URL}/img/square-orange.svg`}
            alt="circle"
          />
          <img
            className="square-2"
            src={`${process.env.PUBLIC_URL}/img/square-blue.svg`}
            alt="circle"
          />
          <img
            className="dot"
            src={`${process.env.PUBLIC_URL}/img/square-orange.svg`}
            alt="circle"
          />
          <img
            className="triangle"
            src={`${process.env.PUBLIC_URL}/img/square-orange.svg`}
            alt="circle"
          />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-8">
              <div className="hero__content">
                <h2 className="hero__title">
                  <span>event technology </span>
                  made simple
                </h2>
                <p>enhancing event experiences, all over the world.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product__area pt-110 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
              <div className="page__title-wrapper text-center mb-60">
                <h2 className="page__title-2">Easy Event Engagement</h2>
                <p>
                  We deliver a suite of eventtech products that make it easy for
                  event organizers to deliver enhanced experiences to their
                  event participants.
                </p>
              </div>
            </div>
          </div>
          <Modules productData={productData} />
        </div>
      </section>
      <LogoList logos={clientSection.logos} header={clientSection.header} />

      <CtaDemo boxStyle="box" />
      <section className=" services__area grey-bg-16 pt-105 pb-110">
        <div className="container">
          <div className="row d-flex h-100">
            <div className="col-lg-4 col-md-6  d-flex flex-column col">
              <div
                className="services__item white-bg mb-30 wow fadeinup h-100"
                data-wow-delay=".3s"
              >
                <div className="services__icon mb-45 text-center">
                  <span className="blue-bg-4">
                    <i className="text-primary fa-2x fad fa-link mt-15"></i>
                  </span>
                </div>
                <div className="services__content">
                  <h3 className="services__title">Easy Integration</h3>
                  <p>Add your event, connect your API. It’s that simple.</p>
                  <p>
                    We take care of integration, so you don't have to, at no
                    additional charge.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex flex-column col">
              <div
                className="services__item white-bg mb-30 wow fadeinup h-100"
                data-wow-delay=".5s"
              >
                <div className="services__icon mb-45 text-center">
                  <span className="pink-bg">
                    <i className="text-danger fa-2x far fa-tachometer-fastest mt-15"></i>
                  </span>
                </div>
                <div className="services__content">
                  <h3 className="services__title">Fast Products</h3>
                  <p>
                    Our lightweight tech scripts ensure products are easy to use
                    and fast to load for every user.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6  d-flex flex-column col">
              <div
                className="services__item white-bg mb-30 wow fadeinup h-100"
                data-wow-delay=".7s"
              >
                <div className="services__icon mb-45 text-center">
                  <span className="green-bg">
                    <i className="text-success fa-2x fal fa-shield mt-15"></i>
                  </span>
                </div>
                <div className="services__content">
                  <h3 className="services__title">Secure Solutions</h3>
                  <p>
                    Protection protocols are built into the software to ensure
                    your event is always protected as well as being compliant
                    with data laws.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChoose />
      <QuickContact />
    </>
  );
}

export default Home;
