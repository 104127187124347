const header = {
  title: "Transmit - SMS",
  content:
    "Send important updates, badge reminders or event entry passes via SMS.",
  bg_img: `${process.env.PUBLIC_URL}/img/shape/shape-1.png`,
};

const transmitSmsSections = [
  {
    title: "What is the Transmit SMS module?",
    content: [
      "This module allows you to schedule and send SMS messages to your event participants, such as registration badge code reminders or other useful updates such as welcome messages or important event information.",
      'Send targeted SMS messages "on-arrival" when your attendees arrive at your event. Segment your data so you\'re sending relevant messages based on registration type, helping enhance event experiences and track the success of your SMS campaigns.',
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Transmit-SMS-2.png`,
    imgAlt: "Transmit SMS 2",
    ImageOnLeft: false,
  },
  {
    title: "How does it work?",
    content:
      "Integrated with your registration data, you simply create and schedule your messages. Transmit SMS then allows you to easily schedule and send personalized SMS messages directly to your event audience.",
    imgSrc: `${process.env.PUBLIC_URL}/img/Transmit-SMS-1.png`,
    imgAlt: "Transmit SMS 1",
    ImageOnLeft: true,
  },
];

const footer = {
  title: "What's included in the event module",
  contentFirst: [
    { text: "Integration with your registration vendor", link: "#" },
    {
      text: "Reporting on SMS sends - see who's getting the messages",
      link: "#",
    },
  ],
  contentSecond: [
    {
      text: "High-value sponsor opportunities with direct, real-time at-event messaging",
      link: "#",
    },
  ],
};
export { transmitSmsSections, header, footer };
