import React, { useState, useContext, useEffect } from "react";
import formFields from "../constants/demoFormFields";
import Form from "../components/form";
import postData from "../service/postData";
import fetchData from "../service/fetchData";
import {
  FlashDispatchContext,
  errorFlash,
  successFlash,
} from "../contexts/flash";
import useAPI from "../service/useAPI";
import { useNavigate } from "react-router-dom";
import paths from "../constants/routers";
import SEO from "../components/seo";
import { metaData } from "../constants/metaData";
import generateModifiedPaths from "../service/generateModifiedPaths";

function Demo() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(formFields);
  const postAPI = useAPI(postData);
  const fetchAPI = useAPI(fetchData);
  const FlashDispatch = useContext(FlashDispatchContext);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        value: e.target.value,
      },
    });
    if (e.target.name === "country") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const dialcode = selectedOption.getAttribute("data-dialcode");
      setFormData({
        ...formData,
        telephone: {
          ...formData.telephone,
          dialCode: dialcode === null ? "" : "+" + dialcode,
        },
        [e.target.name]: {
          ...formData[e.target.name],
          value: e.target.value,
        },
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const interests = formData.interests.options
      .map((interest) => interest.value)
      .join(",");

    const data = {
      first_name: formData.first_name.value,
      last_name: formData.last_name.value,
      job_function: formData.job_function.value,
      company: formData.company.value,
      email: formData.email.value,
      country: formData.country.value,
      telephone: formData.telephone.dialCode + formData.telephone.value,
      interests: interests,
    };
    const submitResponse = await postAPI.request(
      `${process.env.REACT_APP_NODE_URL}/api/contacts/demo`,
      data
    );
    if (submitResponse.ok) {
      successFlash(
        FlashDispatch,
        "Thanks for signing up for the demo. Keep an eye on your email inbox and WhatsApp!"
      );

      // TODO: uncomment this when we have a demo page
      setFormData(formFields);
      navigate(paths.modules);
    } else {
      errorFlash(FlashDispatch, submitResponse.err.response.data.message);
    }
    // reset
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // get countries using fetch

    const getCountries = async () => {
      const countries = await fetchAPI.request(
        `${process.env.REACT_APP_NODE_URL}/api/countries?select=name,code,dialcode&sort=name,ASC`
      );
      if (countries.ok) {
        setFormData({
          ...formData,
          country: {
            ...formData.country,
            options: countries.data.data.map((country) => {
              return {
                label: country.name,
                value: country.code,
                dialCode: country.dialcode,
              };
            }),
          },
        });
      } else {
      }
    };
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    <SEO metaData={metaData.demo}/>
      <div className="bg-shape">
        <img
          srcSet={generateModifiedPaths("/img/shape/shape-1.png")}
          sizes="100vw"
          src={`${process.env.PUBLIC_URL}/img/shape/shape-1.png`}
          alt=""
        />
      </div>
      <section className="pricing__area pt-100 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
              <div className="page__title-wrapper text-center mb-50">
                <h2 className="page__title-2">See what it's all about!</h2>
                <p>
                  No 30-minute sales call, no long pitch to sit through - this
                  is a quick demo of how our modules enhance your events.
                </p>
                <p>
                  Simply complete your details below and you'll receive our{" "}
                  <a href="/modules/passkit">Enhance Pass</a> via the{" "}
                  <a href="/modules/transmit/email">Transmit Email</a> module.
                  Open the email on your phone and you'll have access to your
                  Apple or Google Wallet pass instantly!
                </p>
                <p>
                  If you add your mobile number, we'll send you a test on our{" "}
                  <a href="/modules/transmit/whatsapp">WhatsApp messaging</a>{" "}
                  too!
                </p>
              </div>
            </div>
          </div>
          <div className="tab-content wow fadeInUp" data-wow-delay=".5s">
            <div className="row">
              <div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <div className="pricing__item transition-3 mb-30">
                  <div className="form-group">
                    <Form
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      formData={formData}
                      submitButtonText="Submit Demo"
                    />

                    <div className="sign__action d-flex justify-content-between mb-30 text-muted mt-25">
                      We will never share your info with any third parties. By
                      submitting the demo, you agree that we can contact you
                      about the products we offer. You can request for your
                      information to be removed at any time.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Demo;
