const paths = {
  home: "/",
  "modules::transmit": "/modules/transmit",
  "modules::transmit::email": "/modules/transmit/email",
  "modules::transmit::whatsapp": "/modules/transmit/whatsapp",
  "modules::transmit::telegram": "/modules/transmit/telegram",
  "modules::transmit::sms": "/modules/transmit/sms",
  "modules::passkit": "/modules/enhanced-pass",
  "modules::leaderboard": "/modules/leaderboard",
  "modules::showcase": "/modules/showcase",
  "modules::pricing": "/modules/pricing",
  "modules::demo": "/modules/demo",
  "modules::cpd": "/modules/cpd-certification",
  "modules::floorplan": "/modules/floorplan",
  modules: "/modules",
  support: "/support",
  about: "/about",
  contact: "/contact",
  quickcontact: "/quickcontact",
  "legal::privacy": "/legal/privacy",
  "legal::terms": "/legal/terms",
  login: "https://modules.events-enhanced.com/manager/login",
  blogs: "/blogs",
  blogdetails: "/blog-details/:slug",
  blogCategory: "/blog-category/:category_slug",
};

export default paths;
