import paths from "./routers";
import { Link } from "react-router-dom";
import { supportEmail } from "./companyContactInfo";

const supportData = [
  {
    id: "onboarding",
    title: "Onboarding & Training",
    active: true,
    content:
      "When you first join Events Enhanced, we don't overwhelm you with technical jargon. You don't need to be a software engineer to make our modules work hard for you, so you can focus on what matters - delivering your event. Our team will guide you through the setup process and offer personalized training to ensure that you know exactly how to use our modules to their full potential.",
  },
  {
    id: "account-management",
    title: "Account Management & Support",
    active: false,
    content: [
      "Your dedicated account manager will work with you to ensure that Events Enhanced meets your needs, and offer suggestions on how our modules can enhance the attendee experience at your events. They are always available to answer any questions or address any concerns during the event planning process and during the event itself.",
      "We offer support through email, phone, and live chat so that you can reach us in the way most convenient for you.",
    ],
  },
  {
    id: "onsite",
    title: "Global Online & Onsite Support",
    active: false,
    content: [
      "Through our team and event partner businesses, we are able to offer support to events delivered anywhere in the world. This last year we have delivered over 30 events from North America to Europe, the UAE and Asia.",
      "No matter where your event is located, our team will be there to assist you.",
      <>
        We strive to provide the best possible support for our clients to ensure
        successful events. If you have any questions or concerns, please don't
        hesitate to {<Link to={`mailto:${supportEmail}`}>reach out to us</Link>}
        .
      </>,
    ],
  },
  {
    id: "development",
    title: "Software Development & Updates",
    active: false,
    content: [
      "Our team is constantly working on updates and improvements to the Events Enhanced modules to provide a better user experience and incorporate new technologies.",
      "We learn from every interaction and every event delivery and take turn these into updates which are automatically applied to all relevant modules, ensuring that you always have access to the latest features without any interruption to your events.",
      <>
        We value feedback from our clients, and if there's something you'd like
        to see, {<Link to={paths.contact}>drop us a line</Link>}.
      </>,
    ],
  },
  {
    id: "excellence",
    title: "Committed to Excellence",
    active: false,
    content: (
      <>
        We are committed to providing exceptional support for our clients, so
        that your events can reach new heights with Events Enhanced.{" "}
        {<Link to={paths.contact}>Contact us today</Link>} to learn more about
        how we can support your event needs.
      </>
    ),
  },
];

export default supportData;
