import { Link } from "react-router-dom";
import paths from "./routers";

const header = {
  title: "Product Showcase",
  content:
    "Enhanced engagement at your event, drive and experiences around the show floor.",

  bg_img: `${process.env.PUBLIC_URL}/img/shape/shape-1.png`,
  imgSrc: "",
  imgAlt: "",
};

const showcaseSections = [
  {
    title: "What is the product showcase?",
    content:
      "The Product Showcase module enables you to easily create QR codes for products displayed at your event, enabling event participants to collect information about the latest innovations in your industry as well as vote for their favorites.",
    imgSrc: `${process.env.PUBLIC_URL}/img/Product-Showcase---What-Is.png`,
    imgAlt: "showcase stand-up mock",
    ImageOnLeft: false,
  },
  {
    title: "How does it work?",
    content: [
      'Event participants simply scan the product QR code with their smartphone to access information about the product. Each unique scan creates a "vote" for the product, so you can easily track which products are generating the most buzz at your event.',
      "Attendees simply add their email addresses once they've scanned the QR code and then receive an email with the product information they collected.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Product-Showcase---What-Is.png`,
    imgAlt: "showcase stand-up mock",
    ImageOnLeft: true,
  },
  {
    title: "Sustainable event solution",
    content:
      "Say goodbye to the hassle of collecting brochures and business cards - let attendees scan and go. With real-time results, exhibitors can see what products are generating the most buzz among event participants. Make your next trade show or exhibition a success with a Product Showcase.",
    imgSrc: `${process.env.PUBLIC_URL}/img/Product-Showcase---What-Is2.png`,
    imgAlt: "showcase stand-up mock 2",
    ImageOnLeft: false,
  },
  {
    title: "Create more content",
    content:
      "Create more event content by delivering trend reports based on interactions with products by your event attendees.",
    imgSrc: `${process.env.PUBLIC_URL}/img/2.Product Showcase - How It Works.jpeg`,
    imgAlt: "showcase QR code",
    ImageOnLeft: true,
  },
  {
    title: "Why use Product Showcase?",
    content: [
      "This module helps bring added excitement and interaction to your event's show floor or exhibition area. It also allows you to gather valuable data on attendees' interests and product preferences, giving you insight into potential future collaborations or partnerships.",
      "This adds an interactive element to your event and collects valuable data on attendees interests and preferences.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Product-Showcase---What-Is2.png`,
    imgAlt: "showcase stand-up mock 2",
    ImageOnLeft: false,
  },
];

const footer = {
  title: "What's included in the event module",
  contentFirst: [
    { text: "Unlimited QR code creation for you on your event", link: "#" },
    {
      text: "Email to attendees with content they've collected",
      link: "#",
    },
  ],
  contentSecond: [
    {
      text: "Real-time reporting on attendee scans",
      link: "#",
    },
    {
      text: (
        <>
          Connect with our{" "}
          <Link to={paths["modules::leaderboard"]}>
            <span style={{ color: "blue" }}>Leaderboard module</span>
          </Link>{" "}
          to show in real-time voting results
        </>
      ),
      link: "#",
    },
  ],
};
export { showcaseSections, header, footer };
