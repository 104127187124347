const home = {
    title: "Enhance event experiences",
    keywords: "Event experiences, event check-in, event badging, attendee badges, attendee engagement, event marketing, event technology, event application, event software",
    description: "Effortlessly streamline event check-in, communication with event participants and gamify your events with Events Enhanced.",
};

const modules = {
    title: "Enhance event experiences with real-time messaging",
    keywords: `in what way can an event organizer enhance attendees live event experience?
    What are the benefits of real-time messaging for event engagement?
    How does personalized messaging contribute to a better attendee experience?
    How does geolocation technology improve badge collection for attendees?
    Eventtech products, Event organizers, Enhanced experiences, Real-time Messaging, Attendees, Event engagement, Session reminders, Event updates, Enhanced Pass, Fast track entry, Badge printing, Geolocation technology, Gamified Events, Drive engagement, Exhibitor leads, Product Showcase, QR code content collection
    `,
    description: "Easily integrate powerful event technology for enhanced experiences at your exhibition business.",
};

const transmit = {
    title: "Engaging Attendees with personalized WhatsApp, Email and SMS Messaging",
    keywords: `Event Attendees, Enhanced Events, Event Communication Tools, Event Updates, Personalized Event Messaging, Event Audience Segmentation, Event Pass, WhatsApp Event Planning, Event Entry Pass, Event Messaging, Event Comms, Event Marketing
    Keep event attendees informed and engaged.
    How to do marketing in events?
    What is event communication plan?
    Use technology to market your event.
    What types of important updates can be sent through SMS notifications?
    How can event planners use WhatsApp?
    `,
    description: "Send important event updates in real-time to your attendees with ease via Whatsapp, email and SMS by Events Enhanced.",
};

const enhancedPass = {
    title: "Streamline event entry with the Enhanced Pass",
    keywords: `Badge printing, badge print, conference badges, event badges, event badging, conference name badge, conference name badges, custom event badges, event badges, event name badges, event badge printing, on-site event registration, badge printing and check in, event badge options, badge printing, printing name badges, badge for event, onsite registration, printed conference badges, apple watch event badge. Simplify Event Check-In: Introducing the Enhanced Pass Streamlining Event Entry: The Power of the Enhanced Pass, Quick and Efficient Event Access with Enhanced Passes, How can the Enhanced Pass streamline your event check-in process?
    What is the new technology used in event management? Geolocation in events. What is a badge sponsor? What is the event Enhanced Pass?
    `,
    description: "Make event check in a breeze with Apple Wallet or Google Wallet event badges with easy integration to your any online event registration platforms.",
};

const leaderboard = {
    title: "Boost event engagement and interaction with gamification",
    keywords: `Drive event engagement, event gamification, event leaderboard, exhibition gamification,event QR code, events QR code, gamification for events, event app gamification, gamificiation event apps, conference gamification, event gamificaiton ideas, easy gamificaiton ideas, gamify events, What is gamification in events? How do you gamify events? Boost engagement for your events. Exhibitor engagement, collect event content, events leaderboard, increase event revenue`,
    description: "Engage participants with real-time event gamification with our easy to integrate Leaderboard module.",
};

const showcase = {
    title: "Enhance attendee experiences with product QR codes",
    keywords: `Event experiences, event product showcase, event QR code products, event participation, attendee engagement, attendee experiences, product showcase, sustainable event solution, sustainable event content, create post event content, post event content, add interaction to your event, event attendee, best event experiences, attendee journey, attendee, unforgettable attendee experiences, amazing attendee experience,`,
    description: "Enable event participants to collect content easily on any product or feature showcased at your event with a simple to use event QR code generator with great analytics available for the organizer",
};

const demo = {
    title: "Enhanced Events platform and Enhanced Pass Demo",
    keywords: `Event tech demo, event tech, events platform, event technology, event tech live, best event apps, event platform, new event technology, online event software, tech demo, Enhanced Events demo, Enhanced Pass demo`,
    description: "No long sales calls or pitches. Get a demo without speaking to us - click to experience how our modules enhance your events.",
};

const support = {
    title: "Real-time global support from Events Enhanced",
    keywords: `event tech customer support, event techs, event technology service, technology for events, event technology company, tech customer support, Why is having a qualified and experienced team important for event delivery?The importance of customer service in event management,`,
    description: "Partner with seasoned event technology experts with over 50 years of experience in events and exhibitions. We offer real-time global support and rapid solutions at Events Enhanced.",
};

const about = {
    title: "Enhance event experiences with powerful event technology",
    keywords: `event professionals, event planners, event technology, event experience, attendee satisfaction, event expertise, low cost event solutions, event enhancement, event attendee engagement, How do you create a good event experience?`,
    description: "Quick setup, low cost, and no hidden fees our event technology is available 24/7/365. Powerful, user-friendly modules for engaging event experiences.",
};

export const metaData = {
home, modules, transmit, enhancedPass, leaderboard, showcase, demo, support, about
}