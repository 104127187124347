import {useState} from "react";

const useApi = (apiFunc) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);

    const request = async (...args) => {
        setLoading(true);
        setError(false);
        const response = await apiFunc(...args);
        setLoading(false);

        setError(response.ok?false:response.err);
        setData(response.data);
        return response;
    };

    return {data, error, loading, request};
};

export default useApi;
