import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function LogoList({ header, logos }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="pl-50 pr-50 pb-50 ">
      <h2 className="text-center" style={{ color: "black" }}>
        {header}
      </h2>
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container pb-100 pt-100 text-center"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        >
          {logos.map((logo, index) => (
            <img
              key={index}
              className="d-block m-100 align-center"
            src={logo.src}
            alt={logo.alt}
            style={{
              maxHeight: "100px",
              maxWidth: "100%",
              margin: "auto",
            }}
          />
        ))}
      </Carousel>
    </div>
  );
}

export default LogoList;
