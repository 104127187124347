import paths from "./routers";

const transmitSections = [
  {
    id: "email",
    title: "Email",
    description: "Easily schedule and send important event updates.",
    linkTo: paths["modules::transmit::email"],
    delay: ".5s",
    iconSrc: `${process.env.PUBLIC_URL}/img/emailBlue.png`,
    // modal: {
    //   id: "email",
    //   title: "Email",
    //   imgSrc1: `${process.env.PUBLIC_URL}/img/Transmit-Email-1.png`,
    //   imgAlt1: "Transmit Email 1",
    //   imgSrc2: `${process.env.PUBLIC_URL}/img/Transmit-Email-2.png`,
    //   imgAlt2: "Transmit Email 2",
    // },
    // iconSrc2: `${process.env.PUBLIC_URL}/img/Transmit-Email-2.png`,
  },
  {
    id: "whatsapp",
    title: "WhatsApp",
    description:
      "Keep your attendees informed with the right messages at the right time.",
    linkTo: paths["modules::transmit::whatsapp"],
    delay: ".3s",
    iconSrc: `${process.env.PUBLIC_URL}/img/WhatsApp2.png`,
    // iconSrc2: `${process.env.PUBLIC_URL}/img/Transmit-What-2.png`,
    // modal: {
    //   id: "whatsapp",
    //   title: "WhatsApp",
    //   imgSrc1: `${process.env.PUBLIC_URL}/img/Transmit-What-1.png`,
    //   imgAlt1: "Transmit WhatsApp 1",
    //   imgSrc2: `${process.env.PUBLIC_URL}/img/Transmit-What-2.png`,
    //   imgAlt2: "Transmit WhatsApp 2",
    // },
  },
  // {
  // id: "telegram",
  // title: "Telegram",
  // description:
  //   "Keep in touch with your event participants via the fastest growing global messaging platform, Telegram.",
  // link: paths["modules::transmit::telegram"],
  // delay: ".5s",
  // iconSrc: `${process.env.PUBLIC_URL}/img/Transmit-Telegram-1.png`,
  // iconSrc2: `${process.env.PUBLIC_URL}/img/Transmit-Telegram-2.png`,

  // },
  {
    id: "sms",
    title: "SMS",
    description:
      "Send important updates, badge reminders or event entry passes via SMS.",
    linkTo: paths["modules::transmit::sms"],
    delay: ".5s",
    iconSrc: `${process.env.PUBLIC_URL}/img/sms.png`,
    // iconSrc2: `${process.env.PUBLIC_URL}/img/Transmit-SMS-2.png`,
    // modal: {
    //   id: "sms",
    //   title: "SMS",
    //   imgSrc1: `${process.env.PUBLIC_URL}/img/Transmit-SMS-1.png`,
    //   imgAlt1: "Transmit SMS 1",
    //   imgSrc2: `${process.env.PUBLIC_URL}/img/Transmit-SMS-2.png`,
    //   imgAlt2: "Transmit SMS 2",
    // },
  },
];

export default transmitSections;
