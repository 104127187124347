import React, { useState, forwardRef, useImperativeHandle } from "react";
import Modal from "react-bootstrap/Modal";
import "../css/modal.css";
const ImageModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal() {
      handleShow();
    },
  }));

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal_class">
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row d-flex w-100 h-100">
          <div className="col-md-6">
            <img
              className="w-100 h-100"
              src={props.imgSrc1}
              alt={props.imgAlt1}
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className="col-md-6">
            <img
              className="w-100 h-100"
              src={props.imgSrc2}
              alt={props.imgAlt2}
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default ImageModal;
