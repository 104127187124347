import React from "react";
import ProductItem from "./productItem";

function Modules({ productData }) {
  return (
    <div className="row justify-content-center">
      {Object.values(productData).map((product, index) => (
        <ProductItem
          key={index}
          iconSrc={product.iconSrc}
          iconSrc2={product.iconSrc2}
          circleSrc={product.circleSrc}
          title={product.title}
          description={product.description}
          linkTo={product.linkTo}
          delay={product.delay}
          divColor={product.divColor}
          imageColor={product.imageColor}
          modal={product.modal}
        />
      ))}
    </div>
  );
}

export default Modules;
