import React from "react";
import paths from "../constants/routers";
import { Link } from "react-router-dom";
import generateModifiedPaths from "../service/generateModifiedPaths";

function WhyChoose() {
  return (
    <section className="support__area po-rel-z1 pt-100 pb-100">
      <div className="support__shape wow fadeInLeft" data-wow-delay=".9s">
        <img
          src={process.env.PUBLIC_URL + "/img/shape/support-bg.png"}
          srcSet={generateModifiedPaths("/img/shape/support-bg.png")}
          sizes="(min-width: 1333px) 1001px,75vw"
          alt=""
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
            <div className="page__title-wrapper text-center mb-60">
              <h2 className="page__title-2">Why Choose Events Enhanced</h2>
              <p>We're ready to upgrade your event.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 d-flex flex-column">
            <div
              className="support__item mb-30 text-center white-bg transition-3 wow fadeInUp h-100"
              data-wow-delay=".3s"
            >
              <div className="support__content">
                <h3 className="support__title">Quick and easy to set up</h3>
                <p>
                  You’ve already got a lot of things to do to get your event
                  live, let us help take the pressure off and deliver instant
                  engagement.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 d-flex flex-column col-xl-4 col-lg-4 col-md-6">
            <div
              className="support__item mb-30 text-center white-bg transition-3 wow fadeInUp h-100"
              data-wow-delay=".5s"
            >
              <div className="support__content">
                <h3 className="support__title">Low cost and no hidden fees</h3>
                <p>
                  There are no hidden fees with our modules.{" "}
                  <Link to={paths["contact"]}>
                    click here to get in contact
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 d-flex flex-column col-xl-4 col-lg-4 col-md-6">
            <div
              className="support__item mb-30 text-center white-bg transition-3 wow fadeInUp h-100"
              data-wow-delay=".5s"
            >
              <div className="support__content">
                <h3 className="support__title">Available Anywhere</h3>
                <p>
                  Our products are available online, 24/7/365. No need to leave
                  the comfort of your own home or office to work with Events
                  Enhanced. Work on the move with mobile and tablet
                  compatibility across all our products.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 d-flex flex-column col-xl-4 col-lg-4 col-md-6">
            <div
              className="support__item mb-30 text-center white-bg transition-3 wow fadeInUp h-100"
              data-wow-delay=".5s"
            >
              <div className="support__content">
                <h3 className="support__title">Simple Tracking</h3>
                <p>
                  We make sure you get the data you need, quickly. No
                  overcomplicated features. Simple is the way forward.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 d-flex flex-column col-xl-4 col-lg-4 col-md-6">
            <div
              className="support__item mb-30 text-center white-bg transition-3 wow fadeInUp h-100"
              data-wow-delay=".5s"
            >
              <div className="support__content">
                <h3 className="support__title">
                  Powerful, easy to use modules
                </h3>
                <p>
                  All of our modules are powerful tools that help you engage
                  with your event attendees.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 d-flex flex-column col-xl-4 col-lg-4 col-md-6">
            <div
              className="support__item mb-30 text-center white-bg transition-3 wow fadeInUp h-100"
              data-wow-delay=".5s"
            >
              <div className="support__content">
                <h3 className="support__title">
                  Friendly and experienced support
                </h3>
                <p>
                  With over 50 years of event support between them, we’ve got
                  you covered with near 24/7 support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChoose;
