import React, { useState, useEffect } from "react";
import useAPI from "../service/useAPI";
import fetchData from "../service/fetchData";
import generateModifiedPaths from "../service/generateModifiedPaths";
import { Link, useParams } from "react-router-dom";
import BlogSidebar from "../components/blogSidebar";
import formatDate from "../service/formatDate";
function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const fetchAPI = useAPI(fetchData);
  const { category_slug } = useParams();

  async function fetchBlogs() {
    // const blogsData = await fetchAPI.request(
    //   category_slug
    //     ? `${process.env.REACT_APP_NODE_URL}/api/blogPosts?category=${category_slug}`
    //     : `${process.env.REACT_APP_NODE_URL}/api/blogPosts`
    // );
    // setBlogs(blogsData.data.data);

    if (category_slug) {
      const blogsData = await fetchAPI.request(
        `${process.env.REACT_APP_NODE_URL}/api/blogCategories/${category_slug}`
      );
      setBlogs(blogsData.data.data.BlogPosts);
    } else {
      const blogsData = await fetchAPI.request(
        `${process.env.REACT_APP_NODE_URL}/api/blogPosts`
      );
      setBlogs(blogsData.data.data);
    }
  }
  useEffect(() => {
    fetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category_slug]);

  return (
    <main>
      <div className="bg-shape">
        <img
          srcSet={generateModifiedPaths("/img/shape/shape-1.png")}
          sizes="100vw"
          src={`${process.env.PUBLIC_URL}/img/shape/shape-1.png`}
          alt=""
        />
      </div>

      <section className="page__title-area  pt-85">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="page__title-content mb-50">
                <h2 className="page__title">Latest From The Blog</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Blog
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {blogs && (
        <section className="postbox__area pb-120">
          <div className="container">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-lg-8">
                <div className="postbox__wrapper">
                  {blogs.map((blog) => (
                    <article
                      key={blog.id}
                      className="postbox__item format-image fix mb-50 wow fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <div className="postbox__thumb">
                        <Link
                          to={`/blog-details/${blog.slug}`}
                          className="w-img"
                        >
                          {blog.BlogUploads.length > 0 &&
                          blog.banner_image_id ? (
                            blog.BlogUploads.map((upload) =>
                              upload.id === blog.banner_image_id ? (
                                <img
                                style={{objectFit: 'contain'}}
                                width='770px'
                                  height='400px'
                                  key={upload.id}
                                  src={`${process.env.REACT_APP_NODE_URL}/${upload.path}`}
                                  alt={upload.caption}
                                />
                              ) : null
                            )
                          ) : (
                                null                          
                              )
                    }
                        </Link>
                      </div>
                      <div className="postbox__content">
                        <div className="postbox__meta d-flex mb-10">
                          <div className="postbox__tag mr-20">
                            {blog.BlogCategories.map((category) => (
                              <Link
                                className="mx-1"
                                to={"/blog-category/" + category.slug}
                                key={category.id}
                              >
                                {category.label}
                              </Link>
                            ))}
                            {/* <a href="#">{blog.seo_keywords}</a> */}
                            {/* <h6>{blog.seo_keywords}</h6> */}
                          </div>
                          <div className="postbox__date">
                            <span>
                              <i className="fal fa-clock"></i>
                              {formatDate(blog.publish_date)}
                            </span>
                          </div>
                        </div>
                        <h3 className="postbox__title mb-15">
                          <Link to={`/blog-details/${blog.slug}`}>
                            {blog.title}
                          </Link>
                        </h3>
                      </div>
                    </article>
                  ))}
                </div>
              </div>
              <BlogSidebar />
            </div>
          </div>
        </section>
      )}
    </main>
  );
}

export default BlogList;
