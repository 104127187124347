import React, { useState, useEffect } from "react";
import useAPI from "../service/useAPI";
import fetchData from "../service/fetchData";
import generateModifiedPaths from "../service/generateModifiedPaths";
import { useParams, useNavigate, Link } from "react-router-dom";
import BlogSidebar from "../components/blogSidebar";
import formatDate from "../service/formatDate";
import { Helmet } from "react-helmet-async";

function BlogDetails({ title }) {
  const { slug } = useParams();

  const [blog, setBlog] = useState(null);
  const navigate = useNavigate();
  const fetchAPI = useAPI(fetchData);
  async function fetchBlogs() {
    const blogData = await fetchAPI.request(
      `${process.env.REACT_APP_NODE_URL}/api/blogPosts/${slug}`
    );
    if (blogData.err) {
      navigate("/404");
      return;
    }
    setBlog(blogData.data.data);
  }
  useEffect(() => {
    fetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);
  return (
    <main>
      <div className="bg-shape">
        <img
          srcSet={generateModifiedPaths("/img/shape/shape-1.png")}
          sizes="100vw"
          src={`${process.env.PUBLIC_URL}/img/shape/shape-1.png`}
          alt=""
        />
      </div>

      <div className="bg-shape">
        <img src="assets/img/shape/shape-1.png" alt="" />
      </div>
      {blog && (
        <>
          <Helmet>
            <title>{title}</title>
            <meta name="Description" content={blog.seo_description} />
            <meta name="Keywords" content={blog.seo_keywords} />
          </Helmet>
          <section className="page__title-area  pt-85">
            <div className="container">
              <div className="row">
                <div className="col-xxl-12">
                  <div className="page__title-content mb-50">
                    <div className="postbox__meta d-flex postbox__tag">
                      {blog.BlogCategories.map((category) => (
                        <Link
                          className="mx-1"
                          to={"/blog-category/" + category.slug}
                          key={category.id}
                        >
                          {category.label}
                        </Link>
                      ))}
                      {/* <div className="postbox__time">
                        <span>{blog.publish_date}</span>
                      </div> */}
                    </div>
                    <h2 className="page__title">{blog.title}</h2>
                    <div className="postbox__author-2 mt-20">
                      <ul className="d-flex align-items-start">
                        <li>
                          {/* <div className="postbox__author-thumb-2">
                          <img
                            src={`assets/img/blog/author/${blog.author}.jpg`}
                            alt=""
                          />
                        </div> */}
                        </li>
                        <li>
                          <h6>{blog.author}</h6>
                        </li>
                        <li>
                          <h6>{formatDate(blog.publish_date)}</h6>
                          <span>Published</span>
                        </li>
                        <li className="d-none d-sm-block">
                          <span>{blog.views} Views</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="postbox__area pb-120">
            <div className="container">
              <div className="row">
                <div className="col-xxl-8 col-xl-8 col-lg-8">
                  <div className="postbox__wrapper">
                    <div className="postbox__thumb postbox__thumb-2 fix w-img mb-30">
                      {blog.banner_youtube ? (
                        <iframe
                          width="100%"
                          height="500"
                          src={blog.banner_youtube}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      ) : blog.BlogUploads.length > 0 &&
                        blog.banner_image_id ? (
                        blog.BlogUploads.map((upload) =>
                          upload.id === blog.banner_image_id ? (
                            <img
                            style={{objectFit: 'contain'}}
                              width='770px'
                              height='450px'
                              key={upload.id}
                              src={`${process.env.REACT_APP_NODE_URL}/${upload.path}`}
                              alt={upload.caption}
                            />
                          ) : null
                        )
                      ) : (
                        null
                      )}
                    </div>
                    <div className="postbox__details mb-30">
                      {/* <p className="drop-cap">{blog.content}</p> */}
                      <div
                        dangerouslySetInnerHTML={{ __html: blog.content }}
                      ></div>
                    </div>
                    {/* <div className="postbox__details mb-30">
                        <p>{blog.content}</p>
                      </div> */}
                    {/* <div className="postbox__quote-2 mb-50">
                      <blockquote>
                        <h4>
                          “Even if we can go very far in the detail of the user
                          experience terms of design, a bad copy can completely”{" "}
                        </h4>
                        <span>{blog.author}</span>
                      </blockquote>
                    </div> */}
                    <div className="postbox__thumb postbox__thumb-2 fix w-img mb-30">
                      <img src={`assets/img/blog/blog-${blog.id}.jpg`} alt="" />
                    </div>
                    <div className="postbox__details mb-30">
                      <p>{blog.views} Views</p>
                    </div>

                    <div className="postbox__tag postbox__tag-3 d-sm-flex mb-25">
                      <h5>Tagged with:</h5>
                      {blog.BlogCategories.map((category) => (
                        <Link
                          className="mx-1"
                          to={"/blog-category/" + category.slug}
                          key={category.id}
                        >
                          {category.label}
                        </Link>
                      ))}{" "}
                    </div>
                    {/* <div className="postbox__share m-social mb-80">
                      <h5>The share</h5>
                      <ul>
                        <li>
                          <a href="#" className="fb">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="tw">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="pin">
                            <i className="fab fa-pinterest-p"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="link">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                    <div className="postbox__author-3 d-sm-flex grey-bg-2 mb-85">
                      {/* <div className="postbox__author-thumb-3 mr-20">
                        <img
                          src={`assets/img/blog/author/blog-author-${blog.author}.jpg`}
                          alt=""
                        />
                      </div> */}
                      <div className="postbox__author-content">
                        <h4>{blog.author}</h4>
                        <p>{formatDate(blog.publish_date)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <BlogSidebar />
              </div>
            </div>
          </section>
        </>
      )}
    </main>
  );
}

export default BlogDetails;
