import React, { useEffect } from "react";
import {
  leaderboardSections,
  header,
  footer,
} from "../constants/leaderboardSections";
import Sections from "../components/sections";
import SEO from "../components/seo";
import { metaData } from "../constants/metaData";

function Leaderboard(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return (
    <>
      <SEO metaData={metaData.leaderboard}/>
      <Sections header={header} footer={footer} body={leaderboardSections} />
    </>
  );
}

export default Leaderboard;