import React, { useState } from "react";
import { Link } from "react-router-dom";
import paths from "../constants/routers";

function Sidebar({ sidebarOpened, handleSidebarToggle }) {
  const [isFirstMenuOpen, setIsFirstMenuOpen] = useState(false);
  const [isSecondMenuOpen, setIsSecondMenuOpen] = useState(false);

  const handleSecondMenuToggle = () => {
    setIsSecondMenuOpen(!isSecondMenuOpen);
  };
  const handleFirstMenuToggle = () => {
    setIsFirstMenuOpen(!isFirstMenuOpen);
  };
  const handleLinkClick = () => {
    handleSidebarToggle();
  };
  return (
    <div className={`sidebar__area ${sidebarOpened ? "sidebar-opened" : ""}`}>
      <div className="sidebar__wrapper">
        <div className="sidebar__close">
          <button
            className="sidebar__close-btn"
            onClick={handleSidebarToggle}
            id="sidebar__close-btn"
          >
            <span>
              <i className="fal fa-times"></i>
            </span>
            <span>close</span>
          </button>
        </div>
        <div className="sidebar__content">
          <div className="logo mb-40">
            <a href="index.html">
              <img
                src={`${process.env.PUBLIC_URL}/img/ee_logo_cropped_white.png`}
                style={{ maxWidth: "140px" }}
                alt="logo"
              />
            </a>
          </div>
          <div className="mobile-menu mean-container">
            <div className="mean-bar">
              <Link
                to={paths.home}
                className="meanmenu-reveal"
                style={{ right: "0px", left: "auto", display: "inline" }}
                onClick={handleLinkClick}
              >
                <span>
                  <span>
                    <span></span>
                  </span>
                </span>
              </Link>
              <nav className="mean-nav">
                <ul className="submenu  " style={{ display: "none" }}>
                  <li>
                    <Link to={paths.home} onClick={handleLinkClick}>
                      Home
                    </Link>
                  </li>
                  <li className="has-dropdown">
                    <Link to={paths.modules} onClick={handleLinkClick}>
                      Modules
                    </Link>
                    <ul
                      className={`submenu ${isFirstMenuOpen ? "active" : ""}`}
                      style={isFirstMenuOpen ? {} : { display: "none" }}
                    >
                      <li
                        className={`has-dropdown ${
                          isFirstMenuOpen ? "active dropdown-opened" : ""
                        }`}
                      >
                        <Link
                          to={paths["modules::transmit"]}
                          onClick={handleLinkClick}
                        >
                          Transmit
                        </Link>
                        <ul
                          className={`submenu ${
                            isSecondMenuOpen ? "active" : ""
                          }`}
                          style={isSecondMenuOpen ? {} : { display: "none" }}
                        >
                          <li className="active">
                            <Link
                              to={paths["modules::transmit"]}
                              onClick={handleLinkClick}
                            >
                              Transmit Overview
                            </Link>
                          </li>
                          <li className="d-none d-md-block">
                            <hr />
                          </li>
                          <li>
                            <Link
                              to={paths["modules::transmit::email"]}
                              onClick={handleLinkClick}
                            >
                              Email
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={paths["modules::transmit::whatsapp"]}
                              onClick={handleLinkClick}
                            >
                              WhatsApp
                            </Link>
                          </li>
                          {/* <li>
                            <Link to={paths["modules::transmit::telegram"]}>
                              Telegram
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              to={paths["modules::transmit::sms"]}
                              onClick={handleLinkClick}
                            >
                              SMS
                            </Link>
                          </li>
                        </ul>
                        <Link
                          className={`mean-expand ${
                            isSecondMenuOpen ? "mean-clicked" : ""
                          }`}
                          onClick={handleSecondMenuToggle}
                          style={{ fontSize: "18px" }}
                        >
                          <i className="fal fa-plus"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={paths["modules::passkit"]}
                          onClick={handleLinkClick}
                        >
                          Enhanced Pass
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={paths["modules::leaderboard"]}
                          onClick={handleLinkClick}
                        >
                          Leaderboard
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={paths["modules::showcase"]}
                          onClick={handleLinkClick}
                        >
                          Product Showcase
                        </Link>
                      </li>
                      <li className="d-none d-md-block">
                        <hr />
                      </li>
                      {/* <li>
                        <Link to={paths["modules::pricing"]}>Pricing</Link>
                      </li> */}
                      <li>
                        <Link
                          to={paths["modules::demo"]}
                          onClick={handleLinkClick}
                        >
                          Live Demo
                        </Link>
                      </li>
                    </ul>
                    <Link
                      className={`mean-expand ${
                        isFirstMenuOpen ? "mean-clicked" : ""
                      }`}
                      onClick={handleFirstMenuToggle}
                      style={{ fontSize: "18px" }}
                    >
                      <i className="fal fa-plus"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={paths["modules::demo"]} onClick={handleLinkClick}>
                      Live Demo
                    </Link>
                  </li>
                  <li>
                    <Link to={paths.support} onClick={handleLinkClick}>
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link to={paths.about} onClick={handleLinkClick}>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to={paths.blogs} onClick={handleLinkClick}>
                      Blogs
                    </Link>
                  </li>
                  <li className="mean-last">
                    <Link to={paths.contact} onClick={handleLinkClick}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="sidebar__action mt-330">
            <div className="sidebar__login mt-15">
              <Link to={paths.login} target="_blank" onClick={handleLinkClick}>
                <i className="far fa-unlock"></i> Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
