function generateModifiedPaths(imagePath) {
  const widths = [360, 720, 1080, 1440, 1920, 2040];

  if (imagePath.includes(".gif")) {
    return imagePath;
  }

  const parts = imagePath.split("/");
  const directory = parts.slice(0, -1).join("/");
  const filename = parts.slice(-1)[0];

  const modifiedPaths = widths.map((width) => {
    const modifiedPath = `${directory.replaceAll(
      " ",
      "%20"
    )}/${width}w/${filename.replaceAll(" ", "%20")} ${width}w`;
    return modifiedPath;
  });

  const result = modifiedPaths.join(", ");

  return result;
}

export default generateModifiedPaths;
