import React, { useEffect } from "react";
import { passkitSections, header, footer } from "../constants/passkitSections";
import Sections from "../components/sections";
import SEO from "../components/seo";
import { metaData } from "../constants/metaData";

function Passkit(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return (
  <>
    <SEO metaData={metaData.enhancedPass}/>
    <Sections header={header} footer={footer} body={passkitSections} /> 
  </>);
}

export default Passkit;
