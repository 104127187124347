const header = {
  title: "CPD Certification",
  bg_img: `${process.env.PUBLIC_URL}/img/shape/shape-1.png`,
  content: "Allow event participants to earn CPD points at your event.",
  imgSrc: `${process.env.PUBLIC_URL}/img/`,
  imgAlt: "product-details",
};

const cpdSections = [
  {
    title: "What is CPD Certification?",
    content:
      "CPD, or Continuing Professional Development, is a way for professionals to continually update their skills and knowledge in their field. By offering CPD certification at your event, you are giving attendees the opportunity to earn points toward their own professional development.",
    imgSrc: `${process.env.PUBLIC_URL}/img/`,
    imgAlt: "product-details",
    ImageOnLeft: false,
  },
  {
    title: "Why offer CPD point collection at your event?",
    content:
      "Including this certification can also attract professionals looking to fulfil their CPD requirements, adding value for them and offering another reason for professionals to attend your event.",
    imgSrc: `${process.env.PUBLIC_URL}/img/`,
    imgAlt: "product-details",
    ImageOnLeft: true,
  },
  {
    title: "How does it work?",
    content: [
      "As the event organiser or event planner, you can allocate different points towards different sessions or workshops at your event that can be added towards a professionals personal development. Attendees can then collect these points and submit them to their professional body for CPD certification. ",
      "The event participant scans QR codes at relevant areas around your eventonce they've completed a session or workshop that offers CPD points. The collected points are stored in the participants' profile and upon completion of the event, a certificate can be produced which the individual can then submit to their relevant authority or association.",
      "It is important to check with the relevant professional bodies or accreditation organisations in advance to ensure that your event meets their requirements for CPD point collection.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/`,
    imgAlt: "product-details",
    ImageOnLeft: false,
  },
  {
    title: "Generate additional revenue with CPD points",
    content:
      "CPD point collection helps event organisers create new opportunities for attendees to be professionally recognised by attending your event. You can boost ticket sales or registrations by adding CPD points to your event agenda.",
    imgSrc: `${process.env.PUBLIC_URL}/img/`,
    imgAlt: "product-details",
    ImageOnLeft: true,
  },
];
const footer = {
  title: "What's included in the event module",
  contentFirst: [
    { text: "QR code scanning", link: "#" },
    { text: "Storage of collected points", link: "#" },
  ],
  contentSecond: [
    { text: "QR code and points builder", link: "#" },
    { text: "Certificate on completion", link: "#" },
  ],
};
export { cpdSections, header, footer };
