const header = {
  title: "Gamification Leaderboard",
  content: "Drive engagement and measure interaction at your event.",
  bg_img: `${process.env.PUBLIC_URL}/img/shape/shape-1.png`,
  imgSrc: "",
};

const leaderboardSections = [
  {
    title: "What is the gamification leaderboard?",
    content: [
      "The Gamification Leaderboard offers a simple yet effective way to drive engagement and encourage attendees to collect content at your event.",
      "By measuring and monitoring event interaction in real-time, you can measure what is resonating with your audience, what you need to do more of, and where your event can improve.",
      "Adding in gamification to your event creates a points system for different interactions. This not only adds fun and excitement for event participants, but also allows you to collect valuable data that can be used to improve future events.",
      "The Events Leaderboard is the perfect solution for any event planner looking to increase engagement and collect valuable data.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Leaderboard-Stand-Mock-Up.png`,
    imgAlt: "Leaderboard Stand Mock Up",
    ImageOnLeft: false,
  },
  {
    title: "How does it work?",
    content: [
      "An event participant can collect content from your event through their smart phone. With the simple scan of a QR code, event attendees can collect information on sessions or speakers, save new product feature information or collect their own contact records from exhibitors, putting visitors in control of how they follow up and with whom.",
      "The visitor then receives access to all of the content they collected in a simple email, where they can download all of their useful content.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Enhanced-Pass-Leaderboard.gif`,
    // video: true,
    imgAlt: "Enhanced Pass Leaderboard",
    ImageOnLeft: true,
  },
  {
    title: "Generate additional event revenue",
    content: [
      "There are multiple opportunities for you to generate additional revenues from your event by adding a leaderboard or gamification module to your event.",
      "Exhibitors receive more leads through user collected content. You have the ability to monitor exhibitor lead capture, see how they are individually or collectively performing and engaging in better conversation about rebooking their exhibiting space for the following year, or upselling additional lead opportunities.",
      "You could create a higher tier of points system, for example, collecting a tier one sponsor's content could generate greater points than a tier three sponsor, delivering more value back to your top tier sponsors or exhibitors.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Leaderboard-Stand-Mock-Up2.png`,
    imgAlt: "Leaderboard Stand Mock Up 2",
    ImageOnLeft: false,
  },
];

const footer = {
  title: "What's included in the event module",
  contentFirst: [
    { text: "Integration with your registration badge supplier", link: "#" },
    {
      text: "Customisable leaderboard widgets",
      link: "#",
    },
    {
      text: "Real-time reporting on attendee scans",
      link: "#",
    },
  ],
  contentSecond: [
    {
      text: "Support for all types of scanned content",
      link: "#",
    },
    {
      text: "Integrated leaderboard for showcasing on digital signage, for example, to encourage greater interaction of your event participants",

      link: "#",
    },
  ],
};
export { leaderboardSections, header, footer };
