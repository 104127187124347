import { useMediaQuery } from "react-responsive";
import generateModifiedPaths from "../service/generateModifiedPaths";

function SectionCard({
  resize,
  title,
  content,
  imgSrc,
  imgAlt,
  ImageOnLeft,
  video,
}) {
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  const text = () => (
    <div className="col-xxl-6 col-xl-6 col-lg col-md-8">
      <div className="section__title-wrapper mb-50">
        <h2 className="section__title">{title}</h2>
        {Array.isArray(content) ? (
          content.map((item, index) => <p key={index}>{item}</p>)
        ) : (
          <p>{content}</p>
        )}
      </div>
    </div>
  );
  const image = () => (
    <div className="col-xxl-6 col-xl-6 col-lg col-md-4">
      <div className="product__details-thumb w-img mb-30 embed-responsive embed-responsive-16by9">
        {video ? (
          <video className="embed-responsive-item w-100" autoPlay muted loop>
            <source src={imgSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            srcSet={generateModifiedPaths(imgSrc)}
            sizes="(max-width: 575px) 100vm,(max-width: 768px) 516px,(max-width: 992px) 216px,(max-width: 1200px) 456px,(max-width: 1400px) 546px, 636px"
            src={imgSrc}
            alt={imgAlt}
          />
        )}
      </div>
    </div>
  );
  return (
    <section className="grey-bg pt-30 pb-30">
      <div className="container">
        <div className="row align-items-center">
          {ImageOnLeft & isDesktop ? (
            <>
              {image()}
              {text()}
            </>
          ) : (
            <>
              {text()}
              {image()}
            </>
          )}
        </div>
      </div>
    </section>
  );
}
export default SectionCard;
