import React, { useState, useEffect } from "react";
import useAPI from "../service/useAPI";
import fetchData from "../service/fetchData";
import { Link, useParams } from "react-router-dom";

function BlogSidebar() {
  const [blogs, setBlogs] = useState([]);
  const [Categories, setCategories] = useState([]);

  const fetchAPI = useAPI(fetchData);
  const { category_slug } = useParams();

  async function fetchBlogs() {
    const blogsData = await fetchAPI.request(
      `${process.env.REACT_APP_NODE_URL}/api/blogPosts`
    );
    const blogsObject = blogsData.data.data;
    setBlogs(blogsObject.length > 3 ? blogsObject.slice(0, 3) : blogsObject);
    const categoriesData = await fetchAPI.request(
      `${process.env.REACT_APP_NODE_URL}/api/blogCategories`
    );
    setCategories(categoriesData.data.data);
  }
  useEffect(() => {
    fetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category_slug]);

  return (
    <div className="col-xxl-4 col-xl-4 col-lg-4">
      <div className="blog__sidebar-wrapper  ml-30">
        <div className="blog__sidebar mb-30">
          {/* <div className="sidebar__widget mb-30">
            <div className="sidebar__widget-content">
              <div className="sidebar__search-wrapper">
                <form action="#">
                  <input type="text" placeholder="Search ..." />
                  <button type="submit">
                    <i className="fal fa-search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div> */}
          {blogs && (
            <div className="sidebar__widget mb-30">
              <div className="sidebar__widget-title">
                <h3>Recent News</h3>
              </div>
              <div className="sidebar__widget-content">
                <div className="rc__post-wrapper">
                  {blogs.map((post) => (
                    <div
                      key={post.id}
                      className="rc__post d-flex align-items-center"
                    >
                      <div className="rc__thumb mr-15">
                        <Link to={`/blog-details/${post.slug}`}>
                          {post.BlogUploads.length > 0 &&
                          post.banner_image_id ? (
                            post.BlogUploads.map((upload) =>
                              upload.id === post.banner_image_id ? (
                                <img
                                style={{objectFit: 'contain'}}
                                  key={upload.id}
                                  src={`${process.env.REACT_APP_NODE_URL}/${upload.path}`}
                                  alt={upload.caption}
                                  width="60"
                                  height="60"
                                />
                              ) : null
                            )
                          ) : (
                            null
                          )}
                        </Link>
                      </div>
                      <div className="rc__content">
                        <div className="rc__meta">
                          <span>{post.date}</span>
                        </div>
                        <h6 className="rc__title">
                          <Link to={`/blog-details/${post.slug}`}>
                            {post.title}
                          </Link>
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {Categories && (
            <div className="sidebar__widget mb-30">
              <div className="sidebar__widget-title">
                <h3>Categories</h3>
              </div>
              <div className="sidebar__widget-content">
                <div className="sidebar__catagory">
                  <ul>
                    {Categories.map((category) => (
                      <li key={category.id}>
                        <Link to={"/blog-category/" + category.slug}>
                          {category.label} ({category.BlogPosts.length})
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlogSidebar;
