import paths from "./routers";

const productData = {
    transmit: {
      iconSrc: `${process.env.PUBLIC_URL}/img/icon/product-icon-3.png`,
      circleSrc: `${process.env.PUBLIC_URL}/img/icon/product-circle-3.png`,
      title: "Real-time Messaging",
      description:
        "Segment and personalise your messaging to attendees when they arrive at your event via email, text or WhatsApp. Create greater event engagement by sending welcome messages (prepared by time of day), useful session reminders or other important event updates in real-time when your visitors need them most.",
      linkTo: paths["modules::transmit"],
      delay: ".3",
      divColor: "gradient-blue-2",
      imageColor: "blue-img-2",
    },
    passkit: {
      iconSrc: `${process.env.PUBLIC_URL}/img/icon/product-icon-2.png`,
      circleSrc: `${process.env.PUBLIC_URL}/img/icon/product-circle-2.png`,
      title: "Enhanced Pass",
      description: [
        "We make it easy for you to get your event started quickly and efficiently.",
        "Make use of Apple or Google Wallets for fast track entry to your events with fast badge printing.",
        "Using Geolocation technology, as your attendee approaches the event venue, their confirmation pass will be displayed on their phone for easy badge collection.",
      ],
      linkTo: paths["modules::passkit"],
      delay: ".5",
      divColor: "gradient-yellow",
      imageColor: "yellow-img",
    },
    leaderboard: {
      iconSrc: `${process.env.PUBLIC_URL}/img/icon/product-icon-6.png`,
      circleSrc: `${process.env.PUBLIC_URL}/img/icon/product-circle.png`,
      title: "Gamified Events",
      description: [
        "Drive engagement and encourage attendees to collect content.",
        "Content could be collected from sessions, new products or exhibitor leads collected at your events.",
        "Gamify your event and create a points system for different types of interactions and select winners in real-time to share in person or on social media.",
      ],
      linkTo: paths["modules::leaderboard"],
      delay: ".7",
      divColor: "gradient-pink-2",
      imageColor: "",
    },
    showcase: {
      iconSrc: `${process.env.PUBLIC_URL}/img/icon/product-icon-5.png`,
      circleSrc: `${process.env.PUBLIC_URL}/img/icon/product-circle-5.png`,
      title: "Product Showcase",
      description: [
        "Enhanced engagement at your event, drive and experiences around the show floor with QR code content collection.",
        "See what content is generating the most interest or which products are on trend this year at your event.",
      ],
      linkTo: paths["modules::showcase"],
      delay: "1.2",
      divColor: "gradient-purple-2",
      imageColor: "blue-img-2",
    },
  };
export default productData;