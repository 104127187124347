const titles = {
  home: "Events Enhanced, event technology made simple",
  "modules::transmit":
    "Transmit Module, communicate with your customers! | Events Enhanced",
  "modules::transmit::email":
    "Instant, on demand email for your events | Events Enhanced",
  "modules::transmit::whatsapp":
    "Instant, on demand WhatsApp messaging for your events | Events Enhanced",
  "modules::transmit::telegram":
    "Instant, on demand Telegram messaging for your events | Events Enhanced",
  "modules::transmit::sms":
    "Instant, on demand SMS messaging for your events | Events Enhanced",
  "modules::passkit":
    "Streamlined check-in straight from Apple or Google Wallet | Events Enhanced",
  "modules::leaderboard":
    "Engage visitors with your own Gamification Leaderboard | Events Enhanced",
  "modules::showcase":
    "Product Showcase, adding experiences across your show floor | Events Enhanced",
  "modules::pricing": "Transparent module pricing | Events Enhanced",
  "modules::demo":
    "Modules Live Demo, see what your customers see | Events Enhanced",
  "modules::cpd": "CPD Certification for your event | Events Enhanced",
  "modules::floorplan": "",
  modules: "Event Modules | Events Enhanced",
  support: "Events Enhanced, supporting you and your events",
  about: "Events Enhanced, event technology made from the event professionals",
  contact: "Events Enhanced, speak to us today",
  quickcontact: "",
  "legal::privacy": "",
  "legal::terms": "",
  blogs: "Events Enhanced, Blogs",
  blogdetails: "Events Enhanced, Blog",
};

export default titles;
