import React from "react";
import SectionCard from "./sectionCard";
import QuickContact from "./quickContact";
import { Link } from "react-router-dom";
import generateModifiedPaths from "../service/generateModifiedPaths";

function Sections({ header, footer, body }) {
  return (
    <div>
      <div className="bg-shape">
        <img
          srcSet={generateModifiedPaths(header.bg_img)}
          src={header.bg_img}
          sizes="100vw"
          alt=""
        />
      </div>
      {/* support area start */}
      <section className="support__area po-rel-z1 pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
              <div className="page__title-wrapper text-center mb-60">
                <h2 className="page__title-2">{header.title}</h2>
                <p>{header.content} </p>
              </div>

              <div
                className="product__details-thumb text-center mb-30 w-100"
                style={{ width: 600, maxWidth: "100%" }}
              >
                {header.imgSrc &&
                  (header.imgSrc2 ? (
                    <div
                      className="row d-flex justify-content-around"
                      style={{ width: "1000px !important" }}
                    >
                      <div className="col-md-8 col-8 p-0">
                        <img
                          className="w-100 h-100"
                          sizes="(max-width: 575px) 66vw,(max-width: 768px) 360px,(max-width: 992px) 480px,(max-width: 1200px) 640px,(max-width: 1400px) 380px, 440px"
                          srcSet={generateModifiedPaths(header.imgSrc)}
                          src={header.imgSrc}
                          alt={header.imgAlt}
                          style={{ "object-fit": "contain" }}
                        />
                      </div>
                      <div className="col-md-4 col-4 p-0">
                        <img
                          className="w-100 h-100"
                          sizes="(max-width: 575px) 33vw,(max-width: 768px) 180px,(max-width: 992px) 240px,(max-width: 1200px) 320px,(max-width: 1400px) 190px, 220px"
                          srcSet={generateModifiedPaths(header.imgSrc2)}
                          src={header.imgSrc2}
                          alt={header.imgAlt}
                          style={{ "object-fit": "contain" }}
                        />
                      </div>
                    </div>
                  ) : (
                    <img
                      className="w-100 h-100"
                      sizes="(max-width: 575px) 100vw,(max-width: 768px) 516px,(max-width: 992px) 696px,(max-width: 1200px) 936px,(max-width: 1400px) 546px, 639px"
                      srcSet={generateModifiedPaths(header.imgSrc)}
                      src={header.imgSrc}
                      alt={header.imgAlt}
                      style={{ "object-fit": "fill" }}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* support area end */}
      {body.map((section, index) => (
        <SectionCard
          key={index}
          title={section.title}
          content={section.content}
          imgSrc={section.imgSrc}
          imgAlt={section.imgAlt}
          ImageOnLeft={section.ImageOnLeft}
          video={section.video}
          resize={section.resize}
        />
      ))}
      <section className="category__area pt-105 pb-135">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="section__title-wrapper text-center mb-60">
                <h2 className="section__title">{footer.title}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-11 offset-xxl-1 col-xl-11">
              <div className="row">
                <div
                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay=".3s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.3s",
                    animationName: "fadeInUp",
                  }}
                >
                  {footer.contentFirst.map((item, index) => (
                    <div className="blog__item mb-35" key={index}>
                      <p className="blog__text">
                        <Link to={item.link}>{item.text}</Link>
                      </p>
                    </div>
                  ))}
                </div>
                <div
                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay=".7s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.7s",
                    animationName: "fadeInUp",
                  }}
                >
                  {footer.contentSecond.map((item, index) => (
                    <div className="blog__item mb-35" key={index}>
                      <p className="blog__text">{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <QuickContact />
    </div>
  );
}

export default Sections;
