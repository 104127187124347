const header = {
  title: "Transmit - WhatsApp Messaging",
  content:
    "Keep your attendees informed with the right messages at the right time.",

  bg_img: `${process.env.PUBLIC_URL}/img/shape/shape-1.png`,
};

const transmitWhatsAppSections = [
  {
    title: "What is real-time event WhatsApp Messaging?",
    content: [
      "With WhatsApp real-time event messaging, you can send on-arrival welcome messages, important updates to sessions schedules or important VIP only messages -to those only within the venue or those who haven't yet attended.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Transmit-What-2.png`,
    imgAlt: "Transmit Whatsapp 2",
    ImageOnLeft: false,
  },
  {
    title: "Why use WhatsApp Messaging for events?",
    content: [
      "WhatsApp is already used by over 1 billion people worldwide, making it a familiar and easy platform for attendees to use during your event -no need for a separate app download or login information to send important and relevant event updates.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Transmit-What-1.png`,
    imgAlt: "Transmit Whatsapp 1",
    ImageOnLeft: true,
  },
  {
    title: "How does it work?",
    content: [
      "We do the hard work for you - configuring your WhatsApp number and business profile, or integrating your existing WhatsApp Business account.",
      "You can segment, personalise and send relevant messages or important event updates in real-time when your visitors need them most. Once an attendee has entered your event, you can send them time specific messaging based on their arrival time orregistration type.",
      "You set up the messages in advance (or in real-time on the show floor if needed), set to trigger on specific actions such as before a keynote speaker or at a certain time of day.",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Transmit-What-2.png`,
    imgAlt: "Transmit Whatsapp 2",
    ImageOnLeft: false,
  },
  {
    title: "Generate Targeted Revenue",
    content: [
      "Offer your sponsors targeted sponsorship opportunities by being able to segment your audience on the type and who's in attendance at the event. Offer them the chance to send specific promotions, and directions to a stand all in real-time during the event.",
      "You can sell different opportunities based on registration types, so you can offer different tiers of sponsorship. This is an exclusive opportunity to engage with your event audience so should be priced accordingly!",
    ],
    imgSrc: `${process.env.PUBLIC_URL}/img/Transmit-What-1.png`,
    imgAlt: "Transmit Whatsapp 1",
    ImageOnLeft: true,
  },
];

const footer = {
  title: "What's included in the event module",
  contentFirst: [
    { text: "Integration with your registration vendor", link: "#" },
    {
      text: "Advanced reporting detailing attendee journey from all messages",
      link: "#",
    },
  ],
  contentSecond: [
    {
      text: "WhatsApp messaging -send important event updates & generate revenue",
      link: "#",
    },
    {
      text: "High value sponsor opportunities with direct, real-time messaging  ",
      link: "#",
    },
  ],
};
export { transmitWhatsAppSections, header, footer };
