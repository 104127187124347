import React, { useEffect } from "react";
import CtaDemo from "../components/ctaDemo";
import { Link } from "react-router-dom";
import supportData from "../constants/support";
import { supportEmail } from "../constants/companyContactInfo";
import SEO from "../components/seo";
import { metaData } from "../constants/metaData";
import generateModifiedPaths from "../service/generateModifiedPaths";

function Support(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return (
    <>
    <SEO metaData={metaData.support}/>
      <div className="bg-shape">
        <img
          srcSet={generateModifiedPaths("/img/shape/shape-1.png")}
          sizes="100vw"
          src={`${process.env.PUBLIC_URL}/img/shape/shape-1.png`}
          alt=""
        />
      </div>
      <section className="support__area po-rel-z1 pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
              <div className="page__title-wrapper text-center mb-60">
                <h2 className="page__title-2">Customer Support</h2>
                <p className="mt-40">
                  To deliver an event, it is essential to have a team of people
                  who are qualified and experienced in what they do. Our team
                  has over 50 years of experience working in eventtech, and we
                  are proud to offer top-notch customer support for our enhanced
                  event clients.
                </p>
                <p>
                  We believe it's important to work with a partner who can
                  rapidly answer your every need with the Events Enhanced
                  modules, and be able to answer any question to help you
                  deliver enhanced experiences at your events.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <div className="support__thumb text-center">
                <p>
                  Contact us directly at{" "}
                  <Link to={`mailto:${supportEmail}`}>{supportEmail} </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq__area grey-bg-2 pt-105 pb-125">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-3">
              <div className="faq__tab wow fadeInLeft" data-wow-delay=".3s">
                <ul className="nav nav-tabs" id="faqTab" role="tablist">
                  {supportData.map((tab) => (
                    <li className="nav-item" role="presentation" key={tab.id}>
                      <button
                        className={`nav-link ${tab.active ? "active" : ""}`}
                        id={`${tab.id}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#${tab.id}`}
                        type="button"
                        role="tab"
                        aria-controls={tab.id}
                        aria-selected={tab.active}
                      >
                        {tab.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-9">
              <div
                className="faq__tab-content wow fadeInRight"
                data-wow-delay=".7s"
              >
                <div className="tab-content" id="faqTabContent">
                  {supportData.map((tab) => (
                    <div
                      className={`tab-pane fade ${
                        tab.active ? "show active" : ""
                      }`}
                      id={tab.id}
                      role="tabpanel"
                      aria-labelledby={`${tab.id}-tab`}
                      key={tab.id}
                    >
                      <div className="accordion-item accordion-body">
                        {Array.isArray(tab.content) ? (
                          tab.content.map((item, index) => (
                            <p key={index}>{item}</p>
                          ))
                        ) : (
                          <p>{tab.content}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CtaDemo boxStyle="box" />
    </>
  );
}

export default Support;
