import React, { useReducer, createContext } from "react";

const initialState = {
  opened: false,
  message: "",
  type: "",
};

export const FlashStateContext = createContext();
export const FlashDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "CLOSE_FLASH":
      return {
        ...state,
        opened: false,
        message: "",
      };
    case "OPEN_FLASH":
      return {
        ...state,
        opened: true,
        message: action.payload.message,
        type: action.payload.type,
      };

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const FlashProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <FlashDispatchContext.Provider value={dispatch}>
      <FlashStateContext.Provider value={state}>
        {children}
      </FlashStateContext.Provider>
    </FlashDispatchContext.Provider>
  );
};

export const closeFlash = (dispatch) => {
  return dispatch({
    type: "CLOSE_FLASH",
  });
};

export const successFlash = (dispatch, message) => {
  return dispatch({
    type: "OPEN_FLASH",
    payload: {
      message: message,
      type: "success",
    },
  });
};

export const errorFlash = (dispatch, message) => {
  return dispatch({
    type: "OPEN_FLASH",
    payload: {
      message: message,
      type: "error",
    },
  });
};
export const noticeFlash = (dispatch, message) => {
  return dispatch({
    type: "OPEN_FLASH",
    payload: {
      message: message,
      type: "notice",
    },
  });
};
export const warningFlash = (dispatch, message) => {
  return dispatch({
    type: "OPEN_FLASH",
    payload: {
      message: message,
      type: "warning",
    },
  });
};
export default FlashProvider;
