const contactFormFields = {
  first_name: {
    label: "First Name",
    type: "text",
    icon: "fal fa-user",
    value: "",
    show: true,
    regex: "^[a-z,A-Z, ]{3,}$",
    error:
      "Please enter a valid first name. Names must contain only letters and spaces, and be at least 3 characters long.",
  },
  last_name: {
    label: "Last Name",
    type: "text",
    icon: "fal fa-user",
    value: "",
    show: true,
    regex: "^[a-z,A-Z, ]{3,}$",
    error:
      "Please enter a valid last name. Names must contain only letters and spaces, and be at least 3 characters long.",
  },
  job_function: {
    label: "Job Function",
    type: "text",
    icon: "fal fa-user",
    value: "",
    show: true,
    regex: "^[a-z,A-Z, ]{3,}$",
    error:
      "Please enter a valid job function. Names must contain only letters and spaces, and be at least 3 characters long.",
  },

  company: {
    label: "Company Name",
    type: "text",
    icon: "fal fa-building",
    value: "",
    show: true,
  },
  email: {
    label: "Work email",
    type: "email",
    icon: "fal fa-envelope",
    value: "",
    show: true,
  },
  telephone: {
    label: "Telephone",
    type: "tel",
    icon: "fal fa-globe",
    value: "",
    dialCode: "+44",
    show: true,
    autoCompleteOff: true,
    error: "Please enter a valid phone number in the format +1234567890.",
  },
  country: {
    label: "Country",
    type: "select",
    icon: "fal fa-globe",
    value: "GB",
    options: [],
    show: true,
  },
  message: {
    label: "Message",
    type: "textarea",
    placeholder: "Tell us a bit about your project",
    icon: "fal fa-comment",
    value: "",
    show: true,
  },
};

export default contactFormFields;
