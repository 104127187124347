import React, { useEffect } from "react";
import { cpdSections, header, footer } from "../constants/cpdSections";
import Sections from "../components/sections";

function CpdCertification(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return <Sections header={header} footer={footer} body={cpdSections} />;
}

export default CpdCertification;
