import React, { useEffect } from "react";
import {
  showcaseSections,
  header,
  footer,
} from "../constants/showcaseSections";
import Sections from "../components/sections";
import SEO from "../components/seo";
import { metaData } from "../constants/metaData";

function Showcase(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return (
  <>
    <SEO metaData={metaData.showcase}/>
    <Sections header={header} footer={footer} body={showcaseSections} />
  </>
  );
}

export default Showcase;
