import React from "react";
import { Link } from "react-router-dom";
import paths from "../constants/routers";

function CtaDemo(props) {
  const style = props.boxStyle || "default";
  const inner = style === "box";
  const textClass = inner ? " text-white" : "";
  const btnVersion = inner ? "4" : "2";

  const html = () => {
    return (
      <>
        <div className="row">
          <div className="col-xxl-6 offset-xxl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <div
              className={`section__title-wrapper text-center mb-45 wow fadeInUp ${textClass}`}
              data-wow-delay=".3s"
            >
              <h2 className={`section__title ${textClass}`}>
                Try out Events Enhanced for Free
              </h2>
              <p>
                Simply click this link and you'll be taken through a demo that
                uses our Enhance Pass and Transmit Email which will be delivered
                to your inbox. Open it on your phone to receive your Apple or
                Google Wallet pass today!
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-6 offset-xxl-3">
            <div
              className="cta__content text-center wow fadeInUp"
              data-wow-delay=".5s"
            >
              <Link
                to={paths["modules::demo"]}
                className={`m-btn m-btn-border-2 cta__btn active`}
              >
                <span></span> Try Demo Now
              </Link>
              <Link
                to={paths.contact}
                className={`m-btn m-btn-border-${btnVersion} cta__btn`}
              >
                <span></span> Request Callback
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <section className="cta__area pb-140">
      <div className="container">
        {inner ? (
          <div className="cta__bg cta__inner pt-90 pb-90">{html()}</div>
        ) : (
          html()
        )}
      </div>
    </section>
  );
}

export default CtaDemo;
