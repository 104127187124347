import axios from "axios";

const postData = async (baseURL, data) => {
  try {
    const result = await axios.post(baseURL, data, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "X-Page-Referer": window.location.href,
      },
    });
    return { ok: true, data: result.data };
  } catch (err) {
    console.log("\n error is : " + err);
    return { ok: false, err };
  }
};

export default postData;
