import React, { useRef } from "react";
import { Link } from "react-router-dom";
import ImageModal from "./modal";
function ProductItem({
  iconSrc,
  circleSrc,
  title,
  description,
  linkTo,
  delay,
  divColor,
  imageColor,
  iconSrc2,
  modal,
}) {
  const modalRefs = useRef([]);

  const handleOpenModal = (id) => {
    modalRefs.current[id].openModal();
  };
  return (
    <div className="col-lg-4 col-md-6 mb-30">
      <div
        className="h-100 d-flex flex-column col product__item-2 white-bg fix wow fadeInUp"
        data-wow-delay={`${delay}s`}
      >
        <div
          className={`product__thumb-2 ${divColor} p-relative text-center fix`}
        >
          <img className="product-icon" src={iconSrc} alt="" />
          {iconSrc2 && <img className="product-icon" src={iconSrc2} alt="" />}
          <div className="product__thumb-2-shape">
            <img className={imageColor} src={circleSrc} alt="" />
          </div>
        </div>
        <div className="h-100 product__content-2 text-center d-flex flex-column col">
          <h3 className="product__title-2 mt-25">
            <Link to={linkTo}>{title}</Link>
          </h3>
          {Array.isArray(description) ? (
            description.map((item, index) => <p key={index}>{item}</p>)
          ) : (
            <p>{description}</p>
          )}
          <div className="product__btn mt-auto ">
            {modal  ? (
              <>
                <button
                  onClick={() => handleOpenModal(modal.id)}
                  className="m-btn m-btn-border m-btn-border-6"
                >
                  <span></span> details
                </button>
                <ImageModal
                  ref={(el) => (modalRefs.current[modal.id] = el)}
                  title={modal.title}
                  imgSrc1={modal.imgSrc1}
                  imgAlt1={modal.imgAlt1}
                  imgSrc2={modal.imgSrc2}
                  imgAlt2={modal.imgAlt2}
                />{" "}
              </>
            ) : (
              <Link to={linkTo} className="m-btn m-btn-border m-btn-border-6">
                <span></span> details
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductItem;
