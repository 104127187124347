import React, { useEffect, useState, useContext } from "react";
import {
  infoEmail,
  supportEmail,
  supportPhone,
} from "../constants/companyContactInfo";
import { Link } from "react-router-dom";
import useAPI from "../service/useAPI";
import postData from "../service/postData";
import {
  FlashDispatchContext,
  errorFlash,
  successFlash,
} from "../contexts/flash";
import fetchData from "../service/fetchData";

import formFields from "../constants/contactFormFields";
import generateModifiedPaths from "../service/generateModifiedPaths";
import Form from "../components/form";

function Contact(props) {
  const postAPI = useAPI(postData);
  const FlashDispatch = useContext(FlashDispatchContext);
  const [formData, setFormData] = useState(formFields);
  const fetchAPI = useAPI(fetchData);



  useEffect(() => {
    // get countries using fetch

    const getCountries = async () => {
      const countries = await fetchAPI.request(
        `${process.env.REACT_APP_NODE_URL}/api/countries?select=name,code,dialcode&sort=name,ASC`
      );
      if (countries.ok) {
        setFormData({
          ...formData,
          country: {
            ...formData.country,
            options: countries.data.data.map((country) => {
              return {
                label: country.name,
                value: country.code,
                dialCode: country.dialcode,
              };
            }),
          },
        });
      } else {
      }
    };
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        value: e.target.value,
      },
    });
    if (e.target.name === "country") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const dialcode = selectedOption.getAttribute("data-dialcode");
      setFormData({
        ...formData,
        telephone: {
          ...formData.telephone,
          dialCode: dialcode === null ? "" : "+" + dialcode,
        },
        [e.target.name]: {
          ...formData[e.target.name],
          value: e.target.value,
        },
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission here
    const submitResponse = await postAPI.request(
      `${process.env.REACT_APP_NODE_URL}/api/contacts/contact`,
      {
        first_name: formData.first_name.value,
        last_name: formData.last_name.value,
        job_function: formData.job_function.value,
        company: formData.company.value,
        email: formData.email.value,
        country: formData.country.value,
        telephone: formData.telephone.dialCode + formData.telephone.value,
        message: formData.message.value,
      }
    );
    if (submitResponse.ok) {
      successFlash(
        FlashDispatch,
        "Thank you for your interest! We will be in touch shortly."
      );
      setFormData(formFields);
    } else {
      errorFlash(
        FlashDispatch,
        submitResponse.err.response.data.message ||
          "Something went wrong. Please try again later."
      );
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return (
    <>
      {/* bg shape area start */}
      <div className="bg-shape">
        <img
          srcSet={generateModifiedPaths("/img/shape/shape-1.png")}
          sizes="100vw"
          src={`${process.env.PUBLIC_URL}/img/shape/shape-1.png`}
          alt=""
        />
      </div>
      {/* bg shape area end */}

      {/* contact area start */}
      <section className="contact__area pt-105 pb-145">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="page__title-wrapper mb-55">
                <h2 className="page__title-2">Leave Us a Message.</h2>
                <p>
                  Tell us a little about your project and we can deliver the
                  solution most suitable to you.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12 col-xl-12">
              <div className="contact__wrapper  white-bg">
                <div className="contact__form">
                  <Form
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    formData={formData}
                    submitButtonText="Submit"
                    twoInRow={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact area end */}

      {/* contact info area start */}
      <section className="contact__info pt-20 pb-120">
        <div className="contact__info-shape">
          <img
            srcSet={generateModifiedPaths("/img/shape/contact-bg.png")}
            sizes="51vm"
            src={`${process.env.PUBLIC_URL}/img/shape/contact-bg.png`}
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="page__title-wrapper text-center mb-55">
                <h2 className="page__title-2">
                  We'd love to <br />
                  hear from you
                </h2>
                <p>Stay in touch with us</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="contact__item text-center white-bg mb-30 transition-3">
                <div className="contact__icon mb-30 d-flex justify-content-center align-items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/icon/mail.png`}
                    alt=""
                  />
                </div>
                <div className="contact__content">
                  <h4 className="contact__content-title">
                    <Link to={`mailto:${infoEmail}`}>{infoEmail} </Link>{" "}
                  </h4>
                  <h4 className="contact__content-title">
                    <Link to={`mailto:${supportEmail}`}>{supportEmail} </Link>{" "}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact__item text-center white-bg mb-30 transition-3">
                <div className="contact__icon mb-30 d-flex justify-content-center align-items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/icon/phone.png`}
                    alt=""
                  />{" "}
                </div>
                <div className="contact__content">
                  <h4 className="contact__content-title">
                    <Link to={`tel:${supportPhone}`}>{supportPhone} </Link>
                    <br />
                    &nbsp;
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact info area end */}
    </>
  );
}

export default Contact;
