import React, { useContext } from "react";
import {
  FlashStateContext,
  FlashDispatchContext,
  closeFlash,
} from "../contexts/flash";

function Flash() {
  const flashState = useContext(FlashStateContext);
  const FlashDispatch = useContext(FlashDispatchContext);

  let className = "alert";
  switch (flashState.type) {
    case "error":
      className += " alert-danger";
      break;
    case "success":
      className += " alert-success";
      break;
    case "notice":
      className += " alert-info";
      break;
    case "warning":
      className += " alert-warning";
      break;
    default:
      break;
  }

  return (
    <>
      {flashState.opened && (
        <div
          className={`${className} alert-dismissible fade show`}
          role="alert"
        >
          {flashState.message}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => closeFlash(FlashDispatch)}
          ></button>
        </div>
      )}
    </>
  );
}
export default Flash;
