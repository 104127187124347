const demoFormFields = {
  first_name: {
    label: "First Name",
    type: "text",
    icon: "fal fa-user",
    value: "",
    show: true,
    regex: "^[a-z,A-Z, ]{3,}$",
    error:
      "Please enter a valid first name. Names must contain only letters and spaces, and be at least 3 characters long.",
  },
  last_name: {
    label: "Last Name",
    type: "text",
    icon: "fal fa-user",
    value: "",
    show: true,
    regex: "^[a-z,A-Z, ]{3,}$",
    error:
      "Please enter a valid last name. Names must contain only letters and spaces, and be at least 3 characters long.",
  },
  job_function: {
    label: "Job Function",
    type: "text",
    icon: "fal fa-user",
    value: "",
    show: true,
    regex: "^[a-z,A-Z, ]{3,}$",
    error:
      "Please enter a valid job function. Names must contain only letters and spaces, and be at least 3 characters long.",
  },
  company: {
    label: "Company",
    type: "text",
    icon: "fal fa-building",
    value: "",
    show: true,
  },
  email: {
    label: "Email",
    type: "email",
    icon: "fal fa-envelope",
    value: "",
    show: true,
  },
  country: {
    label: "Country",
    type: "select",
    icon: "fal fa-globe",
    value: "GB",
    options: [],
    show: true,
  },
  telephone: {
    label: "WhatsApp",
    type: "tel",
    icon: "fal fa-phone",
    value: "",
    dialCode: "+44",
    show: true,
    autoCompleteOff: true,
    error: "Please enter a valid phone number in the format +1234567890.",
  },
  interests: {
    show: false,
    label: "Interests",
    type: "multi-select",
    icon: "fal fa-globe",
    value: "",
    options: [
      {
        label: "Transmit - WhatsApp",
        value: "transmit-whatsapp",
      },
      {
        label: "Transmit - SMS",
        value: "transmit-sms",
      },
      {
        label: "Transmit - Telegram",
        value: "transmit-telegram",
      },
      {
        label: "Enhanced Pass",
        value: "enhancedpass",
      },
      {
        label: "Leaderboard",
        value: "leaderboard",
      },
      {
        label: "CPD Certification",
        value: "cpd",
      },
      {
        label: "Floorplan",
        value: "floorplan",
      },
      {
        label: "Product Showcase",
        value: "showcase",
      },
    ],
  },
};

export default demoFormFields;
