import React from "react";
// import { BrowserRouter as Router } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Support from "./pages/Support";
import Transmit from "./pages/Transmit";
import Email from "./pages/TransmitEmail";
import WhatsApp from "./pages/TransmitWhatsApp";
// import Telegram from "./pages/TransmitTelegram";
import SMS from "./pages/TransmitSms";
import Passkit from "./pages/Passkit";
import Leaderboard from "./pages/Leaderboard";
import Showcase from "./pages/Showcase";
// import Pricing from "./pages/Pricing";
import Modules from "./pages/Index";
import Demo from "./pages/Demo";
import CpdCertification from "./pages/CpdCertification";
import BackToTop from "./components/bactToTop";
import paths from "./constants/routers";
import titles from "./constants/titles";
import FlashProvider from "./contexts/flash";
import Flash from "./components/flash";
import ScrollToTop from "./components/scrollToTop";
import Page404 from "./pages/404";
// import Loading from "./components/loading";
import { HelmetProvider } from "react-helmet-async";
import BlogList from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";

function App() {
  return (
    <>
      <HelmetProvider>
        <Router>
          <Header />
          <BackToTop />
          <ScrollToTop />
          {/* <Loading /> */}
          <FlashProvider>
            <div className="messages text-center">
              <Flash />
            </div>
            <Routes>
              <Route
                path={paths.home}
                exact
                element={<Home title={titles.home} />}
              />
              <Route
                path={paths.about}
                element={<About title={titles.about} />}
              />
              <Route
                path={paths.contact}
                element={<Contact title={titles.contact} />}
              />
              <Route
                path={paths["modules::demo"]}
                element={<Demo title={titles["modules::demo"]} />}
              />

              <Route
                path={paths.support}
                element={<Support title={titles.support} />}
              />
              <Route
                path={paths["modules::transmit"]}
                element={<Transmit title={titles["modules::transmit"]} />}
              />
              <Route
                path={paths["modules::transmit::email"]}
                element={<Email title={titles["modules::transmit::email"]} />}
              />
              <Route
                path={paths["modules::transmit::whatsapp"]}
                element={
                  <WhatsApp title={titles["modules::transmit::whatsapp"]} />
                }
              />
              {/* <Route
              path={paths["modules::transmit::telegram"]}
              element={
                // <Telegram title={titles["modules::transmit::telegram"]} />
              }
            /> */}
              <Route
                path={paths["modules::transmit::sms"]}
                element={<SMS title={titles["modules::transmit::sms"]} />}
              />
              <Route
                path={paths["modules::passkit"]}
                element={<Passkit title={titles["modules::passkit"]} />}
              />
              <Route
                path={paths["modules::leaderboard"]}
                element={<Leaderboard title={titles["modules::leaderboard"]} />}
              />
              <Route
                path={paths["modules::showcase"]}
                element={<Showcase title={titles["modules::showcase"]} />}
              />
              {/* <Route
              path={paths["modules::pricing"]}
              element={<Pricing title={titles["modules::pricing"]} />}
            /> */}
              <Route
                exact
                path={paths["modules"]}
                element={<Modules title={titles["modules"]} />}
              />
              <Route
                exact
                path={paths["modules::cpd"]}
                element={<CpdCertification title={titles["modules::cpd"]} />}
              />
              <Route
                exact
                path={paths.blogdetails}
                element={<BlogDetails title={titles.blogdetails} />}
              />
              <Route
                path={paths.blogs}
                element={<BlogList title={titles.blogs} />}
              />
              <Route
                path={paths.blogCategory}
                element={<BlogList title={titles.blogs} />}
              />

              <Route path="/*" element={<Page404 title={"Page not found"} />} />
            </Routes>
          </FlashProvider>
          <Footer />
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
